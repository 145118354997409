import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import deposit from "../images/deposit.png"
import reno from "../images/reno.png"
import furniture from "../images/furniture.png"
import arrow from "../images/Arrow.svg";
import manage from "../images/manage.png";

const FlexContainer = styled.div`
  padding: 1.6rem;  
  margin: auto;
  max-width: 1100px;
  @media (min-width: 810px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Card = styled.div`
  width: 100%;
  margin-bottom: 1.6em;
  transition: all 0.5s ease-out;
  background-color: white;
  @media (min-width: 1100px) {
        width: 510px;
        margin: .6em auto;   
  }
  padding: .4em .4em .8em .4em;
  box-sizing: border-box;       
  box-shadow: 0px 4px 29px 0px rgba(255, 199, 1, 0.16);
  border-radius: 8px;
  border: 1px solid #f3f3f3;
  &:hover {
    transform: translateY(-4px);
  }
  ul, li {
    list-style: none;
    border-bottom: .8px solid #dedede;
  }
  button {
    margin-top: 1em;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    background-color: #FFC102;
  }
`;

const CardContent = styled.div`
  padding: .8em;
  transition: all 1s ease-out;
`;

const CardImageContainer = styled.div`
position: relative;
overflow: hidden;
border-radius: 4px;
div {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 1.32em;
  overflow: hidden;
}
`;

const CardImage = styled.img`
  height:240px;
  width: 100%;
  display: block;
  filter: brightness(50%);
  border-radius: 4px;
  object-fit: cover;
  transition: all .2s ease-out;
  &:hover {
    scale: 102%;
  }
`;

const CardTitle = styled.h4`
  margin-top: 1em;
  font-weight: 600;
  font-size: 1.3em;
`;

const CardDescription = styled.p`
  margin-top: 0.5em;
  height: auto;
  transition: all 1s ease-out;
  font-size: 1em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  button.breakdown {
    &:first-of-type {
      background-color: #f3f3f3;
      margin-right: .4em;
      img {
        transform: translate(-10%, -10%);
      }
      
    }
  }
`;

const Cards = ({ weeklyRent }) => {

  const navigate = useNavigate();


  const calculateAdvance = (weeklyRent, months) => {
      return (weeklyRent / 7 * 365) * (months / 12);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the submission logic here
    // console.log(`Renting for: $${rent}`);
    // Navigate to the desired route

    navigate(`/apply?weeklyRent=${weeklyRent}`);
};

  return (
      <FlexContainer>
        <Card onClick={handleSubmit}>
              <CardImageContainer>
                  {/* <Plus><img src={plus} alt="plus icon" /></Plus> */}
                  <CardImage src={manage} alt="Description 1" />
                  <div>Manage Cashflow</div>
              </CardImageContainer>
              <CardContent>
                  <p>Unlock up to</p>
                  <CardTitle>${calculateAdvance(weeklyRent, 3).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (3 Months)</CardTitle>
                  <CardDescription>
                      Get a small rental income advance to meet your cashflow needs without all the pesky paper work.
                  </CardDescription>
        

                  <ButtonContainer>
                      {/* <button className="breakdown" type=""><img src={magnify} alt="magnify" /></button> */}
                      <button onClick={handleSubmit}><img src={arrow} alt="arrow" /></button>
                  </ButtonContainer>
              </CardContent>
          </Card>

          <Card onClick={handleSubmit}>
              <CardImageContainer>
                  {/* <Plus><img src={plus} alt="plus icon" /></Plus> */}
                  <CardImage src={furniture} alt="Description 1" />
                  <div>Purchase Furniture/Renovate</div>
              </CardImageContainer>
              <CardContent>
                <p>Unlock up to</p>
                  <CardTitle>${calculateAdvance(weeklyRent, 6).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (6 Months)</CardTitle>
                  <CardDescription>
                      Use an advance to furnish or renovate one of your properties without all the waiting around.
                  </CardDescription>
        
                  <ButtonContainer>
                      {/* <button className="breakdown" type=""><img src={magnify} alt="magnify" /></button> */}
                      <button onClick={handleSubmit}><img src={arrow} alt="arrow" /></button>
                  </ButtonContainer>
              </CardContent>
          </Card>

          <Card onClick={handleSubmit}>
              <CardImageContainer>
                  <CardImage src={reno} alt="Description 2" />
                  <div>Pay Down Debt</div>
              </CardImageContainer>
              <CardContent>
                  <p>Unlock up to</p>
                  <CardTitle>${calculateAdvance(weeklyRent, 12).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (1 Year)</CardTitle>
                  <CardDescription>
                      Get an advance to better manage your debt obligations.
                  </CardDescription>
                  {/* {expandedDetail === "reno" && <p>Extra content for reno</p>} */}
                  <ButtonContainer>
                      {/* <button className="breakdown" type=""><img src={magnify} alt="magnify" /></button> */}
                      <button onClick={handleSubmit}><img src={arrow} alt="arrow" /></button>
                  </ButtonContainer>
              </CardContent>
          </Card>

          <Card onClick={handleSubmit}>
              <CardImageContainer>
                  <CardImage src={deposit} alt="Description 3" />
                  <div>Finance a Deposit</div>
              </CardImageContainer>
              <CardContent>
                  <p>Unlock up to</p>
                  <CardTitle>${calculateAdvance(weeklyRent, 24).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (2 Years)</CardTitle>
                  <CardDescription>You could even make an advance to finance a deposit on a new property.</CardDescription>
                  {/* {expandedDetail === "deposit" && <p>Extra content for deposit</p>} */}
                  <ButtonContainer>
                      {/* <button className="breakdown" type=""><img src={magnify} alt="magnify" /></button> */}
                      <button onClick={handleSubmit}><img src={arrow} alt="arrow" /></button>
                  </ButtonContainer>
              </CardContent>
          </Card>
      </FlexContainer>
  )
};

export default Cards;
