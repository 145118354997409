import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../images/LogoLight.svg";
import advancedManagement from "../images/betterbooking.png"
import arrow from "../images/Arrow.svg"

const Nav = styled.nav`
    padding: 24px 1.6em; // Adjust padding here if needed
    width: 100%;
    position: fixed;
    z-index: 5;
    background: rgba(255,255,255,.72);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    border-bottom: .8px solid #dedede;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255,255,255,.8);
    flex: 1;
    display: flex;
    div {
      a {
        background-color: #f3f3f3;
        border-radius: 100px;
        padding: 0.4em 1em;
      }
        
        &:first-of-type {
           text-align: left;
        }
        &:nth-of-type(2) {
            // justify-content: center;
            // align-items: center;
            // img {
            //     max-height: 24px;
            // }
        }
    }
`;

const Container = styled.div`
min-width: 32px;
`;

const BackButton = styled.a`
    display: inline;
    // background-color: none;
    color: black;
    font-weight: 600;
    background-color: #f3f3f3;
    border-radius: 100px;
    padding: 0.4em 1em;
    text-decoration: none;
    text-underline: none;
    text-decoration: none;
    img {
      transform: rotate(180deg);
      height: 14px;
      margin-right: 3.2px;
    }
    &:hover {
        // background-color: #f8f8f8;
        // text-decoration: none;
        // text-underline: none;
        // opacity: .5;
    }
`;


const WhiteLabelLogoContainer = styled.div`

  img {
    display: block;
    max-height: 56px;
  }
  div {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 12px;
    color: gray;
    img {
      height: 12px;
      margin-left: .5em;
    }
  }
`;


const DefaultLogo = styled.div`
  img {
    height: 24px;
  }
`;

const NavBarMultiStep = ({ step, setStep }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (step === 1) {
        navigate('/');
    } else {
        setStep(prevStep => prevStep - 1);
    }
};

  // Define a mapping of URL pathnames to logo sources
  const logoMapping = {
    '/demo': advancedManagement,
    // '/demo': advancedManagement
  };

  // Get the logo source based on the URL pathname
  const logoSrc = logoMapping[window.location.pathname] || logo; // Default to a fallback logo

  return (
    <Nav>
      <Container>
        <BackButton onClick={handleBackClick}><img src={arrow} alt="arrow" />Back</BackButton>
      </Container>
      
      {logoSrc !== logo ? ( // Conditionally render based on the logo source
      <WhiteLabelLogoContainer>
        <img id="logo" src={logoSrc} alt="demo logo" />
        <div>
          powered by
          <img src={logo} alt="lefi icon" />
        </div>
      </WhiteLabelLogoContainer>
    ) : (
      <DefaultLogo>
        <img src={logo} alt="lefi logo" />
      </DefaultLogo>
    )}
      <Container>
        <Link style={{padding: ".5em 1em", color: "black", fontWeight: "600"}} to="tel:+64 03 965 9961">Call Us</Link>
      </Container>
    </Nav>
  );
};

export default NavBarMultiStep;