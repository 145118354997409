import styled from "styled-components"

const VideoTesimonialContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  padding: 2em !important;
  margin: 2em auto 2.4em auto;
  @media(min-width: 1100px) {
    padding: 2em !important;
  }
  h2 {
    margin-bottom: 1em;
  }
`;

const VideoTesimonial = () => {
    return (
        <VideoTesimonialContainer>
        <h2>Here It From One of Our Customers	</h2>
        <div style={{padding: "56.6% 0 0 0", position: "relative", maxWidth: "100%", margin: "auto", borderRadius: "16px"}}><iframe src="https://player.vimeo.com/video/875000909?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" style={{position:"absolute", top:"0", left:"0",width: "100%", height:"100%",  borderRadius: "8px", boxShadow: "0px 4px 20px -3px rgba(0, 0, 0, 0.25)"}} title="Sam R. Testimonial LeFi Rent Advances"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
      </VideoTesimonialContainer>
    );
}

export default VideoTesimonial