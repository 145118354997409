import styled from "styled-components";
import { Phone, Mail, Calendar } from 'lucide-react'


const ContactUsContainer = styled.div`
// border-bottom: 1px solid #f3f3f3;
// border-top: 1px solid #f3f3f3;

// border-bottom: 1px solid #f3f3f3;
margin: 2.4em auto;
padding: 1.6em;
max-width: 1100px;
.textContainer {
  h2 {
    margin-bottom: .4em;
  }
  margin-bottom: 1em;
}
  .buttonContainer {
    @media(min-width: 760px) {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      row-gap: 10px; /* spacing between rows */
      column-gap: 1em; /* spacing between columns */
    }
    a {
      margin-bottom: 1em;
      border-radius: 100px;
      background: #f3f3f3;
      font-weight: 600;
      transition: all .1s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      color: black;
      text-decoration: none;
      padding: .8em 1.6em;
      &:hover {
        filter: brightness(.9);
      }
    }
  }
`;

const ContactUs = () => {
    return (
        <ContactUsContainer>
            <div className="textContainer">
            <h2>Get More From your Managed Property Today</h2>
            <p>Get in touch with us to find out how you can unlock more than time from your property manager with a 1:1 session today.</p>
            </div>
            <div className="buttonContainer">
            <a href="tel:+64 03 965 9961"><Phone size="16" color="black" style={{marginRight: "8px"}}/>Call Today</a>
            <a href="mailto:hello@lefi.cash"><Mail size="16" color="black" style={{marginRight: "8px"}}/>Email Us</a>
            <a target="blank" href="https://meetings.hubspot.com/hamish-buttle"><Calendar size="16" color="black" style={{marginRight: "8px"}}/>Discovery Session</a>
            </div>
        </ContactUsContainer>
    )
}

export default ContactUs;
