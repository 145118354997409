// NavbarComponent.js
import React from "react";
import styled from "styled-components";
// import logo from "../images/LogoLight.svg";
import lefiIcon from "../images/logoAlt.svg";
import { Link } from "react-router-dom";

// Define your styled components
const NavbarOuterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(255,255,255,.72);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border-bottom: .8px solid #dedede;

`;

const NavbarInnerContainer = styled.nav`
  display: flex;
  padding: .8em; // Adjust padding here if needed
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: auto;
  .mobHide {
    @media(max-width: 800px) {
          display: none;
        }
  }
    a {
        color: black;
        font-size: 16px;
        font-weight: 600;
    }
  div {
    a {
      margin-left: 2em;
    }
  }
`;

const Logo = styled.div`
  // max-width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  img {
    max-height: 28px;
    margin-right: 4px;
  }
  p {
    color: black;
    font-size: 28px;
    font-weight: 600;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RenAdvanceSubtext = styled.div`
  color: gray;
  font-weight: 500;
  padding: 1.6px 13px;
  border-left: 1px solid #dedede;
  letter-spacing: -.2px;
  margin-left: 8px;
  // border: 1px solid #dedede;
  // border-radius: 100px;
  // padding: .32em .5em;
  // background-color: #fff;
  @media (max-width: 560px) {
    display: none;
  }
`;

const CallLink = styled.a`
  text-align: right;
  p {
    color: black;
  }
  small {
    color: gray;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinksContainer = styled.div`
height: 100% !important;
display: flex;
`;

const Nav = () => {

  return (
    <NavbarOuterContainer>
      <NavbarInnerContainer>

      <Link to="/" style={{textDecoration: "none"}}>
        <FlexWrapper>
        <Logo>
        <img style={{height: "50px"}} src={lefiIcon} alt="lefi icon" />
        {/* <p>LeFi</p> */}
        </Logo>
        <RenAdvanceSubtext>Rental Income Advances</RenAdvanceSubtext>
        <div className="placeholder"></div>
        </FlexWrapper>
        </Link>

        {window.location.pathname !== "/apply" ? (

                <LinksContainer>
                    <CallLink className="mobHide" href="tel:+64 03 965 9961">
                    <div>
                      <p>03 965 9961</p>
                      <small>Weekdays 8:30am - 6pm</small>
                    </div>
                  </CallLink>
                  <LinkContainer>
                      <Link className="btn-solid" to="/apply">Unlock Your Rent</Link>
                  </LinkContainer>
                  </LinksContainer>

            ) : (
            <CallLink href="tel:+64 03 965 9961">
                    <div>
                      <p>03 965 9961</p>
                      <small>Weekdays 8:30am - 6pm</small>
                    </div>
               </CallLink>
           )
        }

      </NavbarInnerContainer>
    </NavbarOuterContainer>
  );
};

export default Nav;
