import React from "react";
import styled from "styled-components";
import logo from "../images/logoAltBlack.svg";

import GoogleReview from "../images/GoogleReview.svg";
import insta from "../images/instagram.svg";
import fb from "../images/facebook.svg";

const FooterContainer=styled.footer`
  background-color: #FFC701;
  border-radius: 8px;
  margin: 1em;
`;

const FooterLogo = styled.img`
  width: 64px;
`;

const StyledFooter = styled.div`
position: relative;
.flex {
  border-bottom: 1.6px solid rgba(0,0,0,.1);
  .footer-menu {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 720px) {
      display: flex;
    }
  }
  .links {
    display: inline-block;
    a {
      color: black;
      font-weight: 600;
      float: right;
      margin: .4em 0;
    }
  }
}
// .sign {
//   position: absolute;
//   z-index: -1;
//   left: -10%;
//   bottom: 0;
//   width: 1320px;
// }
  margin: auto;
  padding: 2em 0 2em;
  // border-radius: 8px;
  max-width: 1100px;
  p {
    font-size: 16px;
    // max-width: 900px;
    color: black;
  }
  .google {
    margin-bottom: .5em;
    img {max-height: 19px;}
  }
  .socials {
    margin-bottom: 2em;
    a {
      margin-right: 1em;
      img {
        height: 19px;
      }
      &:hover {
        opacity: .8;
      }
    }
  }
  .legals {
    // border-top: 1px solid #dedede;
    padding-top: 2.4em;
    p {
      margin-bottom: 1em;
      font-size: 14px;
    }
  }
`;

const Footer = () => {
    const today = new Date();
    
    return (
      <FooterContainer>
        <StyledFooter>
                  {/* <img className="sign" src={asign} alt="sign" /> */}
          <div className="flex">

            <div>
            <div>
              <FooterLogo src={logo} alt="logo" />
            </div>
            <br />
            <div className="footer-menu">
              <p>© LeFi Technologies Ltd {today.getFullYear()} </p>
              <div className="links">
              <a href="/privacy">Privacy Policy</a>
            </div>
            </div>
            <div>
            {/* <br />
            <p>Contact: hello@lefi.cash</p>
            <p>Phone: 03 965 9961</p> */}
            </div>
            <br />
            <br />
            {/* <div className="google">
              <img src={GoogleReview} alt="google review" />
            </div> */}
            {/* <div className="socials">
              <a href="https://instagram.com/lefi_cash?igshid=YTQwZjQ0NmI0OA==" target="_blank" rel="noreferrer"><img src={insta} alt="insta logo" /></a>
              <a href="https://www.facebook.com/profile.php?id=61552963341816" target="_blank" rel="noreferrer"><img src={fb}  alt="facebook logo" /></a>
            </div> */}
            </div>
           

        </div>
        <div className="legals">
        <p>
            LeFi Technologies Ltd (NZBN: 9429051543617) (FSP: FSP1006812) {today.getFullYear()} is a financial technology firm specializing in facilitating advances against future rental income. However, we do not offer loans or credit. Any comparisons to loans or credit made on this website are for illustrative purposes only.
        </p>
        <p>
            The services provided by LeFi Technologies Ltd {today.getFullYear()} do not constitute a credit instrument and are not subject to the Credit Contracts and Consumer Finance Act 2003 (CCCFA). Our offerings are unique and not directly comparable to conventional loans.
        </p>
        <p>
            All commentary and content provided on this website is of a general nature and should not be considered financial advice. Users should consult a financial professional before making any decisions based on the information presented here.
        </p>
        </div>
        </StyledFooter>
      </FooterContainer>
    );
  };
  
  export default Footer;
  