export function sendSlackNotification(formData) {
    const url = "https://sendslacknotification-coubslczjq-uc.a.run.app";
    
    const formattedText = JSON.stringify(formData, null, 2);

    const data = {
      text: formattedText,
    };
  
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        // console.log("Success:", data);
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  }

  export const handleSendEmail = async (formData) => {    
    try {
      const response = await fetch('https://sendmail-coubslczjq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      const data = await response.text();
      // console.log(data); // Check the response from the server

      // Optionally, you can display a success message or redirect to a thank-you page
    } catch (error) {
      console.error('There has been a problem with your fetch operation mailer:', error);
      // Handle the error, display an error message, or take appropriate action
    }
  };

  //hubspot
  export const handleCreateContact = async (formData) => {
    const { personalDetails } = formData;
    const requestBody = {
      contactEmail: personalDetails.email,
      contactFirstName: personalDetails.firstName,
      contactLastName: personalDetails.lastName,
      phone: personalDetails.phone,
      address: 'address', // You'll need to provide the address from somewhere
    };
  
    try {
      const response = await fetch('https://createcontact-coubslczjq-uc.a.run.app', {
        method: 'POST',
        // mode: 'cors', // Use 'cors' mode to allow cross-origin requests
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
  
      const data = await response.text(); // Assuming your Cloud Function responds with JSON
      console.log(data);
      sendSlackNotification(requestBody);
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
};

export const handleCreateDeal = async (formData) => {
  const getUptoAdvanceAmount = formData.monthsAdvance * formData.weeklyRent
  const requestBody = {
    address: formData.investmentAddress,
    amount: getUptoAdvanceAmount,
    dealStage: 'appointmentscheduled',
    timeline: formData.timeline,
    useOfFunds: formData.useOfFunds,
    months: formData.monthsAdvance,
    weeklyRent: formData.weeklyRent
  };

  try {
    const response = await fetch('https://createdeal-coubslczjq-uc.a.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    const data = await response.text(); // Assuming your Cloud Function responds with JSON
    console.log(data);
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
};

export const handleCreateCompany = async (formData) => {
  const { propertyManagerDetails } = formData; // Destructure companyDetails from formData
  const requestBody = {
    // properties: {
      agencyName: propertyManagerDetails.agency,
      phoneNumber: propertyManagerDetails.phone,
    // },
  };

  console.log(requestBody);

  try {
    const response = await fetch('https://createcompany-coubslczjq-uc.a.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody), // Send the mapped data as the request body
    });

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    const data = await response.text(); // Assuming your Cloud Function responds with JSON
    console.log(data);
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
};

export const handleSendUserEmail = async (docId, formData) => {
  try {
    // Ensure docId is not empty
    if (!docId) {
      throw new Error('docId is required');
    }

    // Ensure required fields are present
    if (!formData.personalDetails || !formData.personalDetails.email) {
      throw new Error('Personal details and email are required');
    }

    const requestBody = {
      docId,
      formData: {
        ...formData,
        monthsAdvance: formData.monthsAdvance || 'N/A',
        useOfFunds: formData.useOfFunds || 'N/A',
        weeklyRent: formData.weeklyRent || 'N/A',
        investment_property_address: formData.investmentProperties && formData.investmentProperties.length > 0
          ? formData.investmentProperties[0].address
          : 'N/A'
      }
    };

    // console.log('Request body:', JSON.stringify(requestBody, null, 2));

    const response = await fetch('https://us-central1-leficash.cloudfunctions.net/sendMailToUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorText = await response.text();
      // console.error('Error response:', response.status, errorText);
      throw new Error(`Network response was not ok: ${response.status} ${errorText}`);
    }

    const data = await response.text();
    // console.log('Success response:', data);

  } catch (error) {
    // console.error('There has been a problem with your fetch operation mailer:', error);
    // You might want to set an error state here or show an error message to the user
  }
};