import React from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import {CTA} from "../components/styles/GlobalStyles"

const StyledPrivacyPolicy = styled.div`
    max-width: 720px;
    margin: 0 auto 200px auto;
    ul {
    padding: 0 16px;
        list-style: none;
        li {
            margin-bottom: .5em;
            letter-spacing: -.5px;
            line-height: 172%;
            strong {
                font-weight: 600 !important;
            }
        }
    }
`;

const Privacy = () => {
    return (
    <>
        <Nav/>
        <header>
        <CTA>
        <h1>Privacy Policy</h1>
        </CTA>
        </header>

        <StyledPrivacyPolicy>
    <ul>
        <li><strong>Your privacy:</strong> LeFi Technologies Limited (LeFi, we or us) is committed to ensuring the
            privacy of your information, and to meeting the requirements of the New Zealand Privacy Act 2020 (Privacy
            Act). We want to make sure that you understand how we collect and treat personal information, so please
            read this Policy carefully.</li>
        <li><strong>Application of this Privacy Policy:</strong> This Policy applies to all personal information that
            we collect when we receive an inquiry about our rental advances or an application for an advance, as we
            administer our rental advances, and any information that may be collected when you visit the LeFI website
            or use our app. This Policy should be read in conjunction with any applicable terms of use of our website
            or app, and any Concurrent Lease or other agreement that you enter into with us.</li>
        <li><strong>Changes to this Privacy Policy:</strong> We are a new service, so we keep our Privacy Policy under
            regular review and may change it at any time. We will provide notice of such changes to this Policy on our
            website and, for significant changes, by email to current customers. By continuing to use the Service after
            a change, you are acknowledging and agreeing to the changes in the amended Privacy Policy. This version was
            last updated on [26/3/24].</li>
        <li><strong>What personal information we collect:</strong> We collect personal information about you when you
            enquire about or apply for our services, including online, by email, over the phone and in person. We also
            collect information about you when you take a rental advance from us. We may also collect personal
            information about you if you create an account with us via a website or app. The personal information we
            may collect includes:
            <ul>
                <li>identity information, business information and contact details (for example, your name, date of
                    birth, address and contact details, and any identity verification documents that you submit to
                    us);</li>
                <li>property information, such as the address of your property, who owns it, and whether it is used
                    as a long term or short term rental;</li>
                <li>financial information, including your intended use of advance funds, mortgage balance and rental
                    income;</li>
                <li>copies of any lease documents and mortgage documents that you provide;</li>
                <li>information about any advance you may be eligible for, any advance we provide to you, and how you
                    repay that advance;</li>
                <li>if you create an account on our website or app, your profile information, including your username
                    and password, and your preferences;</li>
                <li>usage information, including information about how you use our website and services;</li>
                <li>marketing and communications information, including your preferences in receiving marketing from
                    us and our third parties and your communication preferences; and</li>
                <li>any other information that you may disclose to us during the course of our relationship with you.
                </li>
            </ul>
        </li>
        <li><strong>Information about third parties:</strong> We may ask for:
            <ul>
                <li>information about the property manager of a rental property, including their name and contact
                    details, so that we can contact them and request information about your property;</li>
                <li>details of any co-owners of your property;</li>
                <li>information about your mortgage, including who your mortgagee is; and</li>
                <li>information about your tenancies, including the tenant's name, how much rent they pay, the length
                    of the tenancy and a copy of your lease.</li>
            </ul>
            When you give information to us about a third party, you must make sure that you are complying with the
            Privacy Act. If you are not sure whether you can give somebody else's personal information to us, then you
            should ask that person's consent first.</li>
        <li><strong>Consequences of not providing requested information:</strong> You are under no obligation to provide
            any requested information to us. However, if you choose to withhold requested information, that may impact
            our ability to provide you with our services or consider you for a rental advance.</li>
        <li><strong>Indirectly collected information:</strong> We also may collect personal information about you
            from third parties, including:
            <ul>
                <li>information from credit reporting agencies and bank transaction history providers (to help us
                    determine your eligibility for a rental advance, and the potential size of the advance);</li>
                <li>information from third party electronic ID check services (to help us verify your identity);</li>
                <li>information from your property manager (if you provide their contact details to us) about your
                    property and rental income;</li>
                <li>copies of your lease documents from your property manager; and</li>
                <li>information about your property from services like LINZ and CoreLogic.</li>
            </ul>
        </li>
        <li><strong>Purpose of collection:</strong> We will use the personal information we collect:
            <ul>
                <li>for the purposes described in clauses 4-6 above;</li>
                <li>otherwise to provide and administer our services, including to consider your eligibility for a
                    rental advance, provide any advance that you and we agree to, and collect repayments from you;</li>
                <li>to carry out credit checks, credit assessments, affordability assessments and verify your
                    identity;</li>
                    <li>if you create an account on our website or app, to identify you when you sign into your account and
                    verify that your account is not being used by others;</li>
                <li>communicate with you in relation to your advance, our services and related matters (for example,
                    support requests, further advances, payment reminders and marketing communications);</li>
                <li>carry out research and marketing analysis so we can better understand and serve you;</li>
                <li>monitor and enforce compliance with applicable law, and any other contract between you and us, and
                    collect debts when due;</li>
                <li>register financing statements in relation to any rental advance that you take out on the New Zealand
                    Personal Property Securities Register;</li>
                <li>continually improve our website and the services; and</li>
                <li>co-operate with any government, industry or regulatory authorities, where required or permitted by
                    applicable law.</li>
            </ul>
        </li>
        <li><strong>Sharing your information:</strong> Unless you have authorized us to do so, we will not rent or
            sell your personal information, and will not disclose it to any third party except:
            <ul>
                <li>to any financiers that we work with, but only to the extent necessary in order to apply for or
                    maintain funding to provide rental advances;</li>
                <li>where disclosure is connected to the purposes for which the information was collected as listed in
                    clause 8;</li>
                <li>to credit reporting agencies, so they can run credit reports in accordance with clause 8(c);</li>
                <li>to our third-party service providers (such as hosting providers) that process your personal
                    information on our behalf in connection with the purposes described in clause 8.</li>
                <li>to debt collection agencies where necessary to help us collect any money that you may owe us;</li>
                <li>to the extent necessary to register financing statements on the New Zealand Personal Property
                    Securities Register;</li>
                <li>to relevant authorities if we consider that you are in breach of the law or our rights; or</li>
                <li>where required, or permitted, by law.</li>
            </ul>
            We may choose to sell our business. In these types of transactions, a customer database is typically one
            of the business assets transferred to the purchaser. You acknowledge and agree that such transfers may
            occur, and that any acquirer of us or our assets may continue to use your personal information as set out
            in this Policy.</li>
        <li><strong>General</strong>
            <ul>
                <li><strong>Cookies:</strong> We may use session-based cookies and similar technologies to collect
                    information relating to the usage of our website and analyze website traffic. Cookies are small
                    pieces of information that are stored in a browser-related file on your computer or device. The
                    cookies we use do not collect any personally identifiable information.</li>
                <li><strong>Opting-out:</strong> You can unsubscribe from any marketing communications from us by
                    following the instructions on any communications sent to you. You can also exercise this right at
                    any time by contacting us using the details at the end of this Privacy Policy.</li>
                <li><strong>Access and correction:</strong> You have rights under the Privacy Act to access and/or
                    correct your personal information held by us at any time. You can do this by contacting us using
                    the details at the end of this Privacy Policy.</li>
                <li><strong>Storage and security of information:</strong> You should be aware that information transmitted
                    over the internet is not always secure. While we will take all reasonable steps to securely store any
                    personal information we collect, you should be aware that we can never completely guarantee security.
                </li>
                <li><strong>Third-party links:</strong> Our website may include links to third-party websites and
                    applications. Clicking on those links may allow third parties to collect or share data about you. We
                    do not control these third-party websites and are not responsible for how they may use your personal
                    information.</li>
                <li><strong>More information about privacy:</strong> This Privacy Policy is governed by New Zealand
                    law. For more information about privacy issues in New Zealand and protecting your privacy, visit the
                    New Zealand Privacy Commissioner's website: <a href="www.privacy.org.nz">www.privacy.org.nz</a>.
                </li>
                <li><strong>Contact us:</strong> You have rights under the Privacy Act to request access to or
                    correction of your personal information. To do that, or if you have any questions about this Privacy
                    Policy, you can get in touch by writing to us at:
                    <ul>
                        <li>Email address: hello@lefi.cash</li>
                        <li>Physical address: Hamish Buttle, Pathfinder Solutions LP, B:Hive Building, 74 Taharoto Road,
                            Takapuna, Auckland, New Zealand 0622</li>
                    </ul>
                    There may be charges associated with providing copies of your personal information to you, where
                    permitted by applicable law. We will always advise you of those charges in advance.
                </li>
            </ul>
        </li>
    </ul>
        </StyledPrivacyPolicy>
        <Footer/>
    </>
    )
}

export default Privacy;