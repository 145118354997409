import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavBarMultiStep from '../components/NavWhiteLabel';
import styled from 'styled-components';
import {Wrapper, WrapperSmall, ButtonRoundedText, ButtonRoundedBorder,  StyledInput, BackgroundImage } from "../components/styles/GlobalStyles";
import arrow from "../images/Arrow.svg";
import bg from "../images/bg.svg";
import {sendSlackNotification, handleSendEmail, handleCreateContact, handleCreateDeal} from "../helpers/index.js";
import man from "../images/propertyManager/man.png";

const BgContainer = styled.div`
overflow: hidden;
position: relative;
height: 100%;
min-height: 100vh;
`;

const FormStepContainer = styled.div`
    margin-top: 160px;
    p {
        margin-bottom: .8em;
    }
    h2 {
        margin-bottom: 1em;
        font-size: 2em;
    }
`;

const Card = styled.div`

width: 100%;
  margin-bottom: 1.6em;
  transition: all 0.16s ease-out;
  background-color: white;
  @media (min-width: 1100px) {
        width: 510px;
        margin: .6em auto;   
  }
  padding: .4em .4em .8em .4em;
  box-sizing: border-box;       
  box-shadow: 0px 4px 29px 0px rgba(255, 199, 1, 0.16);
  border-radius: 1.6px;;
  border: 1px solid #f3f3f3;
  &:hover {
    transform: translateY(-4px);
    outline: 2.4px solid #FFC701;
  }
  ul, li {
    list-style: none;
    border-bottom: .8px solid #dedede;
  }
  button {
    margin-top: 1em;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    background-color: #FFC102;
  }
`;

const CardContent = styled.div`
  padding: .8em;
  transition: all 1s ease-out;
`;

const CardTitle = styled.h4`
  margin-top: 1em;
  font-weight: 600;
  font-size: 1.24em;
`;

const CardDescription = styled.p`
  margin-top: 0.5em;
  height: auto;
  transition: all 1s ease-out;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`;

const FlexContainer = styled.div`
  padding: 1.6rem;  
  margin: auto;
  max-width: 1100px;
  @media (min-width: 810px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Onboarding = styled.div`
  position: relative;
  border-radius: 16px;
  padding: 1.6em;
  margin: 160px auto 0 auto;
  max-width: 560px;
  @media(min-width: 560px) {
    padding: 3.2em;
    margin: 120px auto 0 auto;
  }
  @media(max-width: 560px) {
    border: none;
    background-color: none;
    box-shadow: none;
    backdrop-filter: none;
  }
  h2 {
    margin-bottom: .5em;
  }
  div {
    margin: 1em 0;
    display: flex;
    align-items: center;
    img {
        max-height: 40px;
        margin-right: 1em;
        max-width: 40px;
    }
    div {
        display: block;
        p {
            display: block;
            &:first-of-type {
            }
        }
    }
  }

`;

const MultiFormWhiteLabel = (props) => {
    const [error, setError] = useState(''); // State to store error messages
    const [isRequesting, setIsRequesting] = useState(false);

    const [formData, setFormData] = useState({
        weeklyRent: '',
        monthsAdvance: null,
        useOfFunds: '',
        timeline: '',
        personalDetails: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        investmentAddress: '',
        propertyOwners: [''],
        propertyManagerDetails: {
            agency: '',
            agentsName: '',
            phone: '',
            email: ''
        }
    });

    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
    
    const query = useQuery();

    const weeklyRentFromURL = query.get('weeklyRent');
    const stepFromURL = query.get('step');

    useEffect(() => {
        if (weeklyRentFromURL) {
        setFormData(prevState => ({
            ...prevState,
            weeklyRent: weeklyRentFromURL,
        }));
        }
    }, [weeklyRentFromURL]);

    const calculateAdvance = (weeklyRent, months) => {
        return (weeklyRent / 7 * 365) * (months / 12);
    }

    const [step, setStep] = useState(1);


    useEffect(() => {
        if (stepFromURL) {
        setStep(parseInt(stepFromURL));
        }
    }, [stepFromURL]);

    //whitelabel formData setting, set for each PM
    useEffect(() => {
        // Check the URL path on page load
        if (window.location.pathname === '/demo') {
          // Set the form data based on the URL
          setFormData((prevFormData) => ({
            ...prevFormData, // Spread the existing state
            propertyManagerDetails: {
              agency: 'Advance Property',
              agentsName: 'Hamish',
              phone: '12345678910',
              email: 'hamishexample@advancepropertymanagement.com',
            },
          }));
          console.log('Setting form data...');
        }
      }, []);


    const handleNextClick = async (months) => {
        console.log("Months:", months);

        switch (step) {
            case 2:
                if (formData.weeklyRent === null || formData.weeklyRent === '' || formData.weeklyRent < 250) {
                    setError('Weekly rent over $250 is required.');
                    return;
                }
                console.log(formData);
                break;
            case 3:
                setFormData(prevData => ({
                    ...prevData,
                    monthsAdvance: months
                }));
                break;
            case 4:
                console.log(formData);
                if (formData.useOfFunds === '' || formData.timeline === '') {
                    setError('Please fill in all fields.');
                    return;
                }
                break;
            case 5:
                setIsRequesting(true);
                console.log(formData);
                if (formData.personalDetails.firstName === '' || formData.personalDetails.lastName === '' || formData.personalDetails.email === '' || formData.personalDetails.phone === '' ) {
                    setError('Please enter all your personal details.');
                    return;
                }
                await handleCreateContact(formData);
                setIsRequesting(false);
                break;

            case 6:
                console.log(formData);
                if (formData.investmentAddress === '') {
                    setError('Please add an Address.');
                    return;
                }

                break;

            case 7:
                setIsRequesting(true);
                console.log(formData);
                if (formData.propertyOwners[0] === '') {
                    setError('Please add an owner.');
                    return;
                }
                await handleCreateDeal(formData);
                sendSlackNotification(formData);
                await handleSendEmail(formData);
                setIsRequesting(false);
                break;
            
            case 8:
                console.log("in case 8");
                break;

            default:
                break;
        }

        // Clear any error messages and move to the next step
        setError('');
        setStep(prevStep => prevStep + 1);
    };

    switch (step) {
        case 1:
            return (
                <>
                <BgContainer>
                    <BackgroundImage src={bg} alt="bg" />
                    <NavBarMultiStep setStep={setStep} step={step}/>
                    <Wrapper>
                        <Onboarding>
                            <h2>Introducing Rental Income Advances</h2>
                            <p>Welcome to the future of cashflow flexibility for Landlords. Advance your future rental income and repay it over the long term so you can purchase furniture, renovate, or finance a deposit for a new house all the while staying cashflow positive.</p>
                            <div>
                                <img src={man} alt="face" />
                                <div>
                                    <p>John Doe • CEO </p>
                                    <p>Advanced Property Management</p>
                                    </div>
                                </div>
                            <ButtonRoundedText onClick={handleNextClick}>Next</ButtonRoundedText>
                        </Onboarding>
                    </Wrapper>
               </BgContainer>
                </>
            );
        case 2:
            return (
                <>
               <BgContainer>
               <BackgroundImage src={bg} alt="bg" />
               

                <NavBarMultiStep setStep={setStep} step={step}/>
                <WrapperSmall>
                    <FormStepContainer>
                    <h2>What is your weekly rental income?</h2>
                    <p>Your weekly rent?</p>
                    <StyledInput
                        type="number"
                        placeholder="Weekly Rent"
                        value={formData.weeklyRent}
                        onChange={e => setFormData({ ...formData, weeklyRent: parseFloat(e.target.value) })}
                    />
                    <ButtonRoundedText onClick={handleNextClick}>Next</ButtonRoundedText>
                    {error && <p style={{color: 'red', fontSize: '14px', textAlign: "center"}}>{error}</p>}
                    </FormStepContainer>
                </WrapperSmall>
                </BgContainer>
                </>
            );
        case 3:
            return (
                <>
               <BgContainer>
               <BackgroundImage src={bg} alt="bg" />

                <NavBarMultiStep setStep={setStep} step={step}/>
                <WrapperSmall>
                    <FormStepContainer>
                    <h2>Approximately how much cash do you need?</h2>
                    <p>We can find tune the full amount and term with you later.</p>
                    <br/>
                    </FormStepContainer>
                </WrapperSmall>

            <FlexContainer>
                <Card>
                    <CardContent>
                        <CardTitle >Get upto ${calculateAdvance(formData.weeklyRent, 3).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (3 Months)</CardTitle>
                        <CardDescription>
                        Get a quick cash infusion to manage your cashflow needs.
                        </CardDescription>
                        {/* {expandedDetail === "reno" && <p>Extra content for reno</p>} */}
                        <ButtonContainer>
                            <button onClick={() => handleNextClick(12)}><img src={arrow} alt="arrow" /></button>
                        </ButtonContainer>
                    </CardContent>
                </Card>
            <Card>
    
                <CardContent>
                    <CardTitle >Get upto ${calculateAdvance(formData.weeklyRent, 6).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (6 Months)</CardTitle>
                    <CardDescription>
                    Purchase some new furniture for one of your investment properties.
                    </CardDescription>
                    {/* {expandedDetail === "reno" && <p>Extra content for reno</p>} */}
                    <ButtonContainer>
                        <button onClick={() => handleNextClick(12)}><img src={arrow} alt="arrow" /></button>
                    </ButtonContainer>
                </CardContent>
             </Card>

            <Card>
         
                <CardContent>
                    <CardTitle >Get upto ${calculateAdvance(formData.weeklyRent, 12).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (1 Year)</CardTitle>
                    <CardDescription>
                        Get a rental income advance to do a renovation on another one of your properties.
                    </CardDescription>
                    {/* {expandedDetail === "reno" && <p>Extra content for reno</p>} */}
                    <ButtonContainer>
                        <button onClick={handleNextClick}><img src={arrow} alt="arrow" /></button>
                    </ButtonContainer>
                </CardContent>
             </Card>

             <Card>
          
                <CardContent>
                    <CardTitle >Get upto ${calculateAdvance(formData.weeklyRent, 24).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (1 Year)</CardTitle>
                    <CardDescription>
                        Get an advance to finance a deposit on a new property and grow your portfolio.
                    </CardDescription>
                    {/* {expandedDetail === "reno" && <p>Extra content for reno</p>} */}
                    <ButtonContainer>
                        <button onClick={handleNextClick}><img src={arrow} alt="arrow" /></button>
                    </ButtonContainer>
                </CardContent>
             </Card>
             </FlexContainer>

                </BgContainer>
                
                </>
            );
        case 4:
            return (
            <>
            <BgContainer>
            <BackgroundImage src={bg} alt="bg" />
            <NavBarMultiStep setStep={setStep} step={step}/>
            <WrapperSmall>
            <FormStepContainer>

            <h2>Tell us more about your master plan.</h2>
            <p>What do you want to do with the funds?</p>
            <select
                value={formData.useOfFunds}
                onChange={e => setFormData({ ...formData, useOfFunds: e.target.value })}
            >
                <option value="" disabled selected>Select your purpose</option>
                <option value="Purchase Furniture">Purchase Furniture</option>
                <option value="Make a deposit on another property">Make a deposit on another property</option>
                <option value="Do a renovation on another property">Do a renovation on another property</option>
                <option value="Recapitalise/Protect from Mortgage Stress">Recapitalise/Protect from Mortgage Stress</option>
                <option value="Invest in the stock market">Invest in the stock market</option>
                <option value="Manage Cashflow">Manage Cashflow</option>
                <option value="Personal Use">Personal Use</option>
                <option value="Other">Other</option>
            </select>

            <p>What is your timeline?</p>
            <select
                value={formData.timeline}
                onChange={e => setFormData({ ...formData, timeline: e.target.value })}
            >
                <option value="" disabled>Select your timeline</option>
                <option value="Immediate">Immediate</option>
                <option value="Within 3 Months">Within 3 Months</option>
                <option value="Within 6 Months">Within 6 Months</option>
                <option value="Within a Year">Within a Year</option>
            </select>
            
            <ButtonRoundedText onClick={handleNextClick}>Next</ButtonRoundedText>
            {error && <p style={{color: 'red', fontSize: '14px', textAlign: "center"}}>{error}</p>}
            </FormStepContainer>
        </WrapperSmall>
        </BgContainer>
            </>
            );
        case 5:
            return (
                <>
                <BgContainer>
                <BackgroundImage src={bg} alt="bg" />
                <NavBarMultiStep setStep={setStep} step={step}/>
                <WrapperSmall>
                    <FormStepContainer>
                    
                    <h2>Personal Details</h2>
                    <p>Tell us a little about you.</p>
                    <StyledInput
                        type="text"
                        placeholder="First Name"
                        value={formData.personalDetails.firstName}
                        onChange={e => setFormData({
                            ...formData,
                            personalDetails: { ...formData.personalDetails, firstName: e.target.value }
                        })}
                    />
                    <StyledInput
                        type="text"
                        placeholder="Last Name"
                        value={formData.personalDetails.lastName}
                        onChange={e => setFormData({
                            ...formData,
                            personalDetails: { ...formData.personalDetails, lastName: e.target.value }
                        })}
                    />
                    <StyledInput
                        type="email"
                        placeholder="Email"
                        value={formData.personalDetails.email}
                        onChange={e => setFormData({
                            ...formData,
                            personalDetails: { ...formData.personalDetails, email: e.target.value }
                        })}
                    />
                                        <StyledInput
                        type="phone"
                        placeholder="Phone"
                        value={formData.personalDetails.phone}
                        onChange={e => setFormData({
                            ...formData,
                            personalDetails: { ...formData.personalDetails, phone: e.target.value }
                        })}
                    />
                    <ButtonRoundedText onClick={handleNextClick} disabled={isRequesting}>{isRequesting ? 'Loading...' : 'Next' }</ButtonRoundedText>
                    {error && <p style={{color: 'red', fontSize: '14px', textAlign: "center"}}>{error}</p>}
                    </FormStepContainer>
                </WrapperSmall>
                </BgContainer>
                </>
            );
        case 6:
            return (
                <>
                <BgContainer>
                <BackgroundImage src={bg} alt="bg" />

                <NavBarMultiStep setStep={setStep} step={step}/>
                
                <WrapperSmall>
                    <FormStepContainer>
                    <h2>Address of your Investment Property</h2>
                    <p>This is the address of the property which you want to advance the rent.</p>
                    <StyledInput
                        type="text"
                        placeholder="Investment Address"
                        value={formData.investmentAddress}
                        onChange={e => setFormData({ ...formData, investmentAddress: e.target.value })}
                    />
                    <ButtonRoundedText onClick={handleNextClick} disabled={isRequesting}>{isRequesting ? 'Loading...' : 'Next' }</ButtonRoundedText>
                    {/* <ButtonRoundedTextGray onClick={() => setStep(4)}>Back</ButtonRoundedTextGray> */}
                    </FormStepContainer>
                    {error && <p style={{color: 'red', fontSize: '14px', textAlign: "center"}}>{error}</p>}
                </WrapperSmall>
                </BgContainer>
                </>
            );
        case 7:
            return (
                <>
                <BgContainer>
                <BackgroundImage src={bg} alt="bg" />
                <NavBarMultiStep setStep={setStep} step={step}/>
                

                <WrapperSmall>
                    <FormStepContainer>
                    <h2>Who owns the investment property?</h2>
                    <p>Name of the owner(s)</p>
                    {formData.propertyOwners.map((owner, idx) => (
                        <StyledInput
                            key={idx}
                            type="text"
                            placeholder={`Owner ${idx + 1} name`}
                            value={owner}
                            onChange={(e) => {
                                const newOwners = [...formData.propertyOwners];
                                newOwners[idx] = e.target.value;
                                setFormData({ ...formData, propertyOwners: newOwners });
                            }}
                        />
                    ))}
                    <ButtonRoundedBorder onClick={() => setFormData({ ...formData, propertyOwners: [...formData.propertyOwners, ''] })}>
                        Add Another Owner
                    </ButtonRoundedBorder>
                    <ButtonRoundedText onClick={handleNextClick} disabled={isRequesting}>{isRequesting ? 'Loading...' : 'Next' }</ButtonRoundedText>
                    </FormStepContainer>
                    {error && <p style={{color: 'red', fontSize: '14px', textAlign: "center"}}>{error}</p>}

                </WrapperSmall>
                </BgContainer>
                </>
            );
        case 8:
            return (
                <>
                <BgContainer>
                <BackgroundImage src={bg} alt="bg" />
                 <NavBarMultiStep setStep={setStep} step={step}/>
                 

                <WrapperSmall>
                    <FormStepContainer>
                    <h2>Thank you!</h2>
                    <p>Your application was submitted. We are reviewing your application and one of our rental income advance experts will be in touch shortly.</p>
                    </FormStepContainer>
                </WrapperSmall>
                </BgContainer>
                </>
            );
        default:
            return null;
    }
};

export default MultiFormWhiteLabel;