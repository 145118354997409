import React, {useEffect, useState, useCallback} from "react";
import styled from "styled-components";
import { X, Plus, Menu } from 'lucide-react'
import { useNavigate } from "react-router";
import { useLocation, Link } from 'react-router-dom';
import {WrapperSmall, ButtonRoundedText, ButtonRoundedLight,  StyledInput, StyledInputWeekly, BackgroundImage, Wrapper, WrapperLarge, ButtonRoundedTextGray } from "../components/styles/GlobalStyles";
import { motion } from "framer-motion";
import { handleSendEmail, handleCreateDeal, handleCreateContact, sendSlackNotification, handleSendUserEmail } from "../helpers";
import Autocomplete from 'react-google-autocomplete';
import toast, { Toaster } from 'react-hot-toast';

import {db} from "../firebase";
import { collection, addDoc } from "firebase/firestore";


import arrow from "../images/Arrow.svg";
import burger from "../images/burger.svg";
import logo from "../images/logoAlt.svg";
import tick from "../images/tick.svg";

const addApplication = async (formData) => {
    try {
        // Add the formData object to the 'Applications' collection
        const docRef = await addDoc(collection(db, 'Applications'), formData);
        // console.log('Form data submitted successfully! Document ID:', docRef.id);
        return docRef.id;
    } catch (error) {
        console.error('Error submitting form data:', error);
    }
};

const toastError = (err) => {
    toast.error(err, {style: {
        fontSize: '14px',
        fontWeight: '600',
        color: '#000',
        backgroundColor: '#FFC701'
      }});
}


const StyledAutocomplete = styled(Autocomplete)`
  && {
  border-radius: 100px;
  border: 1px solid #dedede;
  font-size: 16px;
  font-weight: 500;
  background: #fff;
  height: 48px;
  margin-bottom: 1em;
  outline-offset: 1.6px;
  position: relative;
  display: block;
  text-align: left;
  }
`;

const PropertyItem = styled.div`
  &:first-of-type {
    margin-top: 2em;
  }
  padding: .6em 1em;
  margin-bottom: 10px;
  border: 1px solid #dedede;
  border-radius: 8px;
  position: relative; /* Make the parent container relative */
  background-color: #f9f9f9;

  p {
    font-weight: 600;
    color: black;
    font-size: 14px;
    white-space: nowrap;      /* Prevents text from wrapping to the next line */
    overflow: hidden;         /* Hides overflowing text */
    text-overflow: ellipsis;
  }

  span {
    font-size: 14px;
    color: gray;
  }
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  position: absolute; /* Position the button absolutely within the parent container */
  right: 10px; /* Position the button 10px from the right edge */
  top: 10px; /* Position the button 10px from the top edge */
  width: auto; /* Ensure the button doesn't inherit full width */
  height: auto; /* Ensure the height is auto */
  display: inline-flex; /* Make sure the button is sized according to its content */
  align-items: center; /* Align content within the button */
  justify-content: center; /* Center content within the button */
`;


const StyledNav = styled.nav`
    height: 56px;
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    border-bottom: 1px solid #f3f3f3;
    background-color: white;
    z-index: 10;
    .left, .right, .center {
        display: flex;
        align-items: center;
        flex: 1;
        // border: 1px solid #f3f3f3;
        height: 100%;
    }
    .left {
        padding-left: 1em;
        justify-content: flex-start;
    }
    .right {
        padding-right: 1em;
        justify-content: flex-end;
    }
    .center {
        justify-content: center;
        img {height: 24px;}
    }

`;

const StyledProgressBar = styled.div`
    width: 110vw;
    top: 0;
    left: -1em;
    height: 8px;
    z-index: 5;
    border-radius: 100px;
    background-color: #f3f3f3;  
    position: absolute;
    overflow: hidden;
    &::after {
        content: "";
        width: ${props => props.width};
        height: 8px;
        background-color: #FFC701;
        position: absolute;
        border-radius: 100px;
        z-index: 1;
        transition: all .3s ease-in-out;
    }
`;

const ButtonGray = styled.button`
    background-color: #f3f3f3;
    display: inline-block;
    padding: .5em .6em;
    width: 108px;
    border-radius: 100px;
    font-weight: 600;
    height: auto;
    margin-bottom: 0;
    color: black;
    img {
        margin-right: .5em;
    }
    .back {
        transform: rotate(180deg);  
        height: 12px;  
    }
    &:hover {
        opacity: .5;
    }
`;

const StyledSection = styled(motion.section)`
height: auto;
width: 100%;
z-index: 100;
margin-bottom:160px;
padding-top: 120px;
.titleContainer {
    margin-bottom: 2em;
    h2 {
        margin-bottom: .2em;
    }
}
div {
    // max-width: 560px;
    // top: -5em;
    position: relative;
    label {
        text-align: left;
        padding: 1em 0;
        font-weight: 600;
    }
}
`;

const StepsMenu = styled.div`
    position: fixed;
    right: ${(props) => (props.isopen ? '0' : '-350px')}; /* Use isOpen only for styling */
    top: 56px;
    width: 350px;
    height: calc(100% - 100px);
    padding: 2em 1em;
    transition: all .2s ease-in-out;
    background-color: #fff;
    z-index: 5;
    border-left: 1px solid #f3f3f3;
    overflow-Y: scroll;
    h3{
        font-weight: 600;
        margin-bottom: 1em;
    }
    ul {
        list-style: none;
        counter-reset: list-counter; /* Create a new instance of counter */
        position: relative;
        li {
            margin-bottom: 2em;
            counter-increment: list-counter;
            h4 {
                font-size: 19px;
                font-weight: 600;
                letter-spacing: -1px;
                padding: 0 .8em 0;
                margin: 0;
                // border-left: 1px solid #f3f3f3;
                &::before {
                    content: counter(list-counter);
                    position: absolute;
                    left: -4px;
                }
            }
            p {
                padding: .5em 1em 0 1em;
                margin: 0;
                // border-left: 1px solid #f3f3f3;
            }
        }
    }
`;

const StyledFooter = styled.footer`
    width: 100vw;
    // background-color: green;
    border-top: 1px solid #f3f3f3;
    position: fixed;
    bottom: 0;
    height: 80px;
    padding: 0em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 6;
    background-color: white;
    gap: .5em;
    @media (min-width: 600px) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        gap: 3.2em;
        height: 40px;
        flex-direction: row;
    }
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
    }
    div img {
        height: 1.2em;
        margin-right: .5em;
    }

`;

const ProgressBar = ({ progress }) => {
    return <StyledProgressBar width={progress}></StyledProgressBar>;
}

const StyledError = styled.p`
    color: red;
    font-size: 14px;
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
`

const StyledInputWrapper = styled.div`
position: relative;
display: block;
&::after {
    content: "$";
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%); /* Center vertically */
    pointer-events: none; /* Make sure the pseudo-element doesn't interfere with input focus */
    font-weight: 500;
    font-size: 1em;

  }
`;

const variants = {
    hidden: {
      opacity: 0,
      y: 24, // adjust value as needed
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

const StepOne = ({ formData, setFormData, onNextStep, setError, error, setProgress }) => {
    useEffect(() => {
        setProgress("10%");
        window.scrollTo(0, 0);
    }, []); 

    const handleLocalNextStep = async () => {
        // UPDATED: Improved validation
        if (!formData.weeklyRent || isNaN(formData.weeklyRent) || formData.weeklyRent < 250) {
            // setError('Please enter a valid weekly rent amount over $250.');
            toast.error('Please enter a valid weekly rent amount over $250.', {style: {
                fontSize: '14px',
                fontWeight: '600',
                color: '#000',
                backgroundColor: '#FFC701'
              }});
            return;
        }
        // setError(''); // Clear error if validation passes
        onNextStep();
    };

    return (
        <StyledSection
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
            type: 'spring',
            stiffness: 180, // Adjust as needed
            damping: 10,    // Adjust as needed
            mass: 0.75,     // Adjust as needed
            delay: .2,     // 0.5s delay
            }}
        >
            <WrapperSmall>
                <div className="titleContainer">
                    <h2>Let's get started <br/>unlocking your future rental income.</h2>
                </div>
                <label htmlFor="weeklyRent">Your Weekly Rental Income</label>
                <StyledInputWrapper>
                <StyledInputWeekly
                    id="weeklyRent"
                    name="weeklyRent"
                    aria-label="weeklyRent"
                    autoFocus
                    type="number"
                    placeholder="Weekly Rent"
                    value={formData.weeklyRent || ''}
                    onChange={e => setFormData({ ...formData, weeklyRent: parseFloat(e.target.value) })}
                />
                </StyledInputWrapper>
                <ButtonRoundedText onClick={handleLocalNextStep}>Next</ButtonRoundedText>
                {/* {error && <StyledError>{error}</StyledError>} */}
            </WrapperSmall>
        </StyledSection>
    );
};

const Card = styled.div`
  width: 100%;
  transition: all 0.16s ease-out;
  background-color: white;
  @media (min-width: 1100px) {
    flex: 1 1 49%;
  }
  padding: .4em .4em .8em .4em;
  box-sizing: border-box;       
  box-shadow: 0px 4px 29px 0px rgba(255, 199, 1, 0.16);
  border-radius: 4px;
  border: 1px solid #dedede;
  &:hover {
    transform: translateY(-4px);
    outline: 2.4px solid #FFC701;
  }
  button {
    margin-top: 1em;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    background-color: #FFC102;
  }
`;

const CardContent = styled.div`
  padding: .8em;
  transition: all 1s ease-out;
`;

const CardTitle = styled.h4`
  font-weight: 600;
  font-size: 1.24em;
`;

const CardDescription = styled.p`
  margin-top: 0.5em;
  height: auto;
  transition: all 1s ease-out;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  height: auto;
  padding: 0;
  margin: 0;
`;

const FlexContainer = styled.div`
display: flex;
flex-direction: column;
  gap: 1em;
  @media (min-width: 720px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;

  }
  .filler {
    height: 200px;
}
`;

const StepTwo = ({ formData, setFormData, onNextStep, setError, error, setProgress }) => {
    useEffect(() => {
        setProgress("20%");
        window.scrollTo(0, 0);
    }, []); 

    const calculateAdvance = (weeklyRent, months) => {
        const amount = (weeklyRent / 7 * 365) * (months / 12);
        return Math.min(amount, 100000); // Cap at $100,000
    }

    const handleLocalNextStep = (months) => {
        setFormData(prevData => ({
            ...prevData,
            monthsAdvance: months
        }));
        onNextStep();
    };

    const options = [
        { months: 3, description: "Get a quick cash infusion to manage your cashflow needs." },
        { months: 6, description: "Purchase some new furniture for one of your investment properties." },
        { months: 12, description: "Get a rental income advance to do a renovation on another one of your properties." },
        { months: 24, description: "Get an advance to finance a deposit on a new property and grow your portfolio." }
    ];

    return (
        <StyledSection
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
                type: 'spring',
                stiffness: 180,
                damping: 10,
                mass: 0.75,
                delay: .2,
            }}
        >
            <WrapperSmall>
                <div className="titleContainer">
                    <h2>Approximately, how much $$$ do you need?</h2>
                    <p>We will fine tune the full amount and term with you later. The maximum advance amount is $100,000.</p>
                </div>
            </WrapperSmall>
            <WrapperLarge>
                <FlexContainer>
                    {options.map(option => {
                        const advanceAmount = calculateAdvance(formData.weeklyRent, option.months);
                        return (
                            <Card key={option.months} onClick={() => handleLocalNextStep(option.months)}>
                                <CardContent>
                                    <p>Advance up to</p>
                                    <CardTitle>
                                        ${advanceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                                        ({option.months} Months)
                                    </CardTitle>
                                    <ButtonContainer>
                                        <button onClick={() => handleLocalNextStep(option.months)}>
                                            <img src={arrow} alt="arrow" />
                                        </button>
                                    </ButtonContainer>
                                </CardContent>
                            </Card>
                        );
                    })}
                    <div className="filler"></div>
                </FlexContainer>
            </WrapperLarge>
        </StyledSection>
    );
};

const StepThree = ({ formData, setFormData, onNextStep, setError, error, setProgress }) => {
    useEffect(() => {
        setProgress("30%");
        window.scrollTo(0, 0);
    }, []);

    const handleChange = (field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }));
        setError(''); // Clear error when user makes a selection
    };

    const handleLocalNextStep = () => {
        if (!formData.useOfFunds || formData.useOfFunds === "Select your purpose" ||
            !formData.timeline || formData.timeline === "Select your timeline") {
            // setError('Please make your selections.');
            toastError('Please make your selections.')
            return;
        }
        setError(''); // Clear error if validation passes
        onNextStep();
    };

    return(
        <StyledSection
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
                type: 'spring',
                stiffness: 180,
                damping: 10,
                mass: 0.75,
                delay: .2,
            }}
        >
            <WrapperSmall>
                <div className="titleContainer">
                    <h2>Tell us more about your master plan.</h2>
                    <p>What do you want to do with the funds and what is your timeline?</p>
                </div>
                <select
                    id="useOfFunds"
                    name="useOfFunds"
                    value={formData.useOfFunds}
                    onChange={e => handleChange('useOfFunds', e.target.value)}
                    aria-label="Use of Funds"
                >
                    <option value="">Select your purpose</option>
                    <option value="Purchase furniture">Purchase Furniture</option>
                    <option value="Fund a deposit">Fund a deposit</option>
                    <option value="Renovate another property">Renovate another property</option>
                    <option value="Purchase a Minor Dwelling/Tiny Home">Purchase a Minor Dwelling/Tiny Home</option>
                    <option value="Maintenance">Maintenance</option>
                    <option value="Invest in the stock market">Invest in the stock market</option>
                    <option value="Manage cashflow">Manage Cashflow</option>
                    <option value="Personal use">Personal Use</option>
                    <option value="Other">Other</option>
                </select>
                <select
                    id="timeline"
                    name="timeline"
                    value={formData.timeline}
                    onChange={e => handleChange('timeline', e.target.value)}
                    aria-label="What is your timeline?"
                >
                    <option value="">Select your timeline</option>
                    <option value="Immediate">Immediate</option>
                    <option value="Within 3 Months">Within 3 Months</option>
                    <option value="Within 6 Months">Within 6 Months</option>
                    <option value="Within a Year">Within a Year</option>
                </select>
                <ButtonRoundedText onClick={handleLocalNextStep}>Next</ButtonRoundedText>
                {/* {error && <StyledError>{error}</StyledError>} */}
            </WrapperSmall>
        </StyledSection>
    );
};
const StepFour = ({ formData, setFormData, onNextStep, setError, error, setProgress }) => {
    useEffect(() => {
        setProgress("40%");
        window.scrollTo(0, 0);
    }, []);

    const handleInputChange = (key, value) => {
        setFormData(prev => ({
            ...prev,
            personalDetails: { ...prev.personalDetails, [key]: value }
        }));
        setError(''); // Clear error on input change
    };

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleLocalNextStep = () => {
        const { firstName, lastName, email, phone } = formData.personalDetails;
        if (!firstName || !lastName || !email || !phone) {
            // setError('Please fill in all fields.');
            toastError('Please fill in all fields.');

            return;
        }
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            toast.error('Please enter a valid email address.', {style: {
                fontSize: '14px',
                fontWeight: '600',
                color: '#000',
                backgroundColor: '#FFC701'
              }});
            return;
        }
        setError(''); // Clear error if validation passes
        onNextStep();
    };

    const fields = [
        { type: 'text', placeholder: 'First Name', key: 'firstName', id: 'firstName', name: 'firstName', autocomplete: 'given-name' },
        { type: 'text', placeholder: 'Last Name', key: 'lastName', id: 'lastName', name: 'lastName', autocomplete: 'family-name' },
        { type: 'email', placeholder: 'Email', key: 'email', id: 'email', name: 'email', autocomplete: 'email' },
        { type: 'tel', placeholder: 'Phone', key: 'phone', id: 'phone', name: 'phone', autocomplete: 'tel' },
    ];

    return(
        <>
        <StyledSection
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
                type: 'spring',
                stiffness: 180,
                damping: 10,
                mass: 0.75,
                delay: .2,
            }}
        >
        <WrapperSmall>
            <div className="titleContainer">
                <h2>Personal Details</h2>
                <p>Let's get to know each other.</p>
            </div>
            {fields.map(field => (
                <StyledInput
                    key={field.key}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    placeholder={field.placeholder}
                    value={formData.personalDetails[field.key]}
                    onChange={e => handleInputChange(field.key, e.target.value)}
                    autoComplete={field.autocomplete}
                    aria-label={field.placeholder}
                />
            ))}
            <ButtonRoundedText onClick={handleLocalNextStep}>Next</ButtonRoundedText>
            {/* {error && <StyledError>{error}</StyledError>} */}
        </WrapperSmall>
        </StyledSection>
        </>
    );
};

const AddedPropertyCard = styled.div`
  width: 100%;
  transition: all 0.16s ease-out;
  background-color: white;
  padding: 1em;
  box-sizing: border-box;       
  box-shadow: 0px 4px 29px 0px rgba(255, 199, 1, 0.16);
  border-radius: 4px;
  border: 1px solid #dedede;
  margin-bottom: 20px;
  position: relative;
`;

const AddedPropertyContent = styled.div`
  transition: all 1s ease-out;
`;

const AddedPropertyTitle = styled.h4`
  font-weight: 600;
  font-size: 1.24em;
  margin: 0 0 0.5em 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 40px; // Make room for the remove button
`;

const AddedPropertyDescription = styled.p`
  margin: 0;
  color: #666;
`;

const RemoveAddedPropertyButton = styled.button`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
    padding: 0;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const StepFive = ({ formData, setFormData, onNextStep, setError, error, setProgress }) => {
    useEffect(() => {
        setProgress("50%");
        window.scrollTo(0, 0);
    }, []); 

    const [newProperty, setNewProperty] = useState({ address: '', type: '' });

    const addProperty = () => {
        if (newProperty.address && newProperty.type) {
            setFormData(prev => ({
                ...prev,
                investmentProperties: [...prev.investmentProperties, newProperty]
            }));
            setNewProperty({ address: '', type: '' });
            setError('');
        } else {
            // setError('Please fill in both address and property type.');
            toastError('Please fill in both address and property type.');

        }
    };

    const removeProperty = (index) => {
        setFormData(prev => ({
            ...prev,
            investmentProperties: prev.investmentProperties.filter((_, i) => i !== index)
        }));
    };

    const handleLocalNextStep = () => {
        if (formData.investmentProperties.length === 0) {
            // setError('Please fill in the given fields and press "add property" to proceed.');
            toastError('Please fill in the given fields and press "add property" to proceed.');
            return;
        }
        setError('');
        onNextStep();
    };

    return(
        <StyledSection
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
                type: 'spring',
                stiffness: 180,
                damping: 10,
                mass: 0.75,
                delay: .2,
            }}
        >
        <WrapperSmall>
            <div className="titleContainer">
                <h2>Your Investment Properties</h2>
                <p>Please add all your investment properties below.</p>
            </div>
            <StyledAutocomplete
                    apiKey={String(process.env.REACT_APP_GOOGLE_API_KEY)} // Replace with your actual Google API key
                    onPlaceSelected={(place) => {
                        setNewProperty({ ...newProperty, address: place.formatted_address });
                    }}
                    options={{
                        types: ['address'],
                        componentRestrictions: { country: "nz" } // Adjust to your specific needs
                    }}
                    placeholder="Investment Address"
                    value={newProperty.address}
                    onChange={(e) => setNewProperty({ ...newProperty, address: e.target.value })}
                />
            <select
                id="propertyType"
                name="propertyType"
                value={newProperty.type}
                onChange={e => setNewProperty({...newProperty, type: e.target.value})}
            >
                <option value="" disabled>What type of property is it?</option>
                <option value="Long Term Rental">Long Term Rental</option>
                <option value="Short Term Rental">Short Term Rental</option>
                <option value="Rental Arbitrage">Rent to Rent / Short term rental Arbitrage</option>
            </select>
            <ButtonRoundedLight onClick={addProperty}><Plus color="black" size="19px"/>Add Property</ButtonRoundedLight>
            


            <ButtonRoundedText onClick={handleLocalNextStep}>Next</ButtonRoundedText>
            {error && <StyledError>{error}</StyledError>}
            {formData.investmentProperties != 0  && <label>Properties:</label>}
            {formData.investmentProperties.map((property, index) => (
                <AddedPropertyCard key={index}>
                    <AddedPropertyContent>
                        <AddedPropertyTitle>{property.address}</AddedPropertyTitle>
                        <AddedPropertyDescription>{property.type}</AddedPropertyDescription>
                    </AddedPropertyContent>
                    <RemoveAddedPropertyButton onClick={() => removeProperty(index)}>
                        <X size={16} color="black"/>
                    </RemoveAddedPropertyButton>
                </AddedPropertyCard>
            ))}
        </WrapperSmall>
        </StyledSection>
    );
};

const SelectBox = styled.div`
  margin-bottom: 20px;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const RadioButton = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

// const variants = {
//   hidden: { opacity: 0, y: 24 },
//   visible: { opacity: 1, y: 0 },
// };

const StepSix = ({ formData, setFormData, onNextStep, setError, error, setProgress }) => {
    useEffect(() => {
        setProgress("60%");
    }, [setProgress]);

    const handleOwnershipChange = (type) => {
        if (type === 'personal') {
            setFormData(prev => ({
                ...prev,
                isPersonalOwnership: true,
                isCompanyOrTrustOwnership: false,
                isJointOwnership: false,
                ownerCount: 1,
                additionalOwners: []
            }));
        } else if (type === 'companyOrTrust') {
            setFormData(prev => ({
                ...prev,
                isPersonalOwnership: false,
                isCompanyOrTrustOwnership: true,
                isJointOwnership: false,
                ownerCount: 1,
                additionalOwners: []
            }));
        }
    };

    const handleJointOwnershipChange = (isJoint) => {
        setFormData(prev => ({
            ...prev,
            isJointOwnership: isJoint,
            ownerCount: isJoint ? 2 : 1,
            additionalOwners: isJoint ? [{ firstName: '', lastName: '', email: '', phone: '' }] : []
        }));
    };

    const handleOwnerCountChange = (count) => {
        setFormData(prev => ({
            ...prev,
            ownerCount: count,
            additionalOwners: Array(count - 1).fill().map((_, index) => 
                prev.additionalOwners[index] || { firstName: '', lastName: '', email: '', phone: '' }
            )
        }));
    };

    const handleAdditionalOwnerChange = (index, field, value) => {
        setFormData(prev => ({
            ...prev,
            additionalOwners: prev.additionalOwners.map((owner, i) => 
                i === index ? { ...owner, [field]: value } : owner
            )
        }));
    };

    const handleNextStep = () => {
        if (!formData.isPersonalOwnership && !formData.isCompanyOrTrustOwnership) {
            // setError('Please select an ownership structure.');
            toastError('Please select an ownership structure.');

            return;
        }

        if (formData.isPersonalOwnership && formData.isJointOwnership === undefined) {
            // setError('Please specify whether this is a joint ownership.');
            toastError('Please specify whether this is a joint ownership.');
            return;
        }

        if (formData.isJointOwnership) {
            if (formData.ownerCount < 2) {
                // setError('Joint ownership must have at least 2 owners.');
                toastError('Joint ownership must have at least 2 owners.');
                return;
            }
            const allOwnerDetailsComplete = formData.additionalOwners.every(owner => 
                owner.firstName && owner.lastName && owner.email && owner.phone
            );
            if (!allOwnerDetailsComplete) {
                // setError('Please complete all additional owner details.');
                toastError('Please complete all additional owner details.');
                return;
            }
        }

        // setError('');
        onNextStep();
    };

    return (
        <StyledSection>
            <WrapperSmall>
                <div className="titleContainer">
                    <h2>Ownership Structure</h2>
                    <p>Please specify the ownership structure of your property.</p>
                </div>

                <PropertyCard onClick={() => handleOwnershipChange('personal')}>
                    <PropertyCardContent>
                        <PropertyCardTitle>Personal Ownership</PropertyCardTitle>
                        <PropertyCardDescription>You own the property in your name.</PropertyCardDescription>
                        <PropertyButtonContainer>
                            <PropertySelectButton selected={formData.isPersonalOwnership} />
                        </PropertyButtonContainer>
                    </PropertyCardContent>
                </PropertyCard>

                <PropertyCard onClick={() => handleOwnershipChange('companyOrTrust')}>
                    <PropertyCardContent>
                        <PropertyCardTitle>Company or Trust Ownership</PropertyCardTitle>
                        <PropertyCardDescription>The property is owned by a company or trust.</PropertyCardDescription>
                        <PropertyButtonContainer>
                            <PropertySelectButton selected={formData.isCompanyOrTrustOwnership} />
                        </PropertyButtonContainer>
                    </PropertyCardContent>
                </PropertyCard>

                {formData.isPersonalOwnership && (
                    <>
                        <label>Is this a joint ownership?</label>
                        <PropertyCard onClick={() => handleJointOwnershipChange(false)}>
                            <PropertyCardContent>
                                <PropertyCardTitle>Single Owner</PropertyCardTitle>
                                <PropertyCardDescription>Only you own the property.</PropertyCardDescription>
                                <PropertyButtonContainer>
                                    <PropertySelectButton selected={!formData.isJointOwnership} />
                                </PropertyButtonContainer>
                            </PropertyCardContent>
                        </PropertyCard>

                        <PropertyCard onClick={() => handleJointOwnershipChange(true)}>
                            <PropertyCardContent>
                                <PropertyCardTitle>Joint Ownership</PropertyCardTitle>
                                <PropertyCardDescription>The property is jointly owned.</PropertyCardDescription>
                                <PropertyButtonContainer>
                                    <PropertySelectButton selected={formData.isJointOwnership} />
                                </PropertyButtonContainer>
                            </PropertyCardContent>
                        </PropertyCard>

                        {formData.isJointOwnership && (
                            <>
                                <label>How many owners are there in total?</label>
                                <select
                                    value={formData.ownerCount}
                                    onChange={(e) => handleOwnerCountChange(Number(e.target.value))}
                                >
                                    {[2, 3, 4].map(num => (
                                        <option key={num} value={num}>{num}</option>
                                    ))}
                                </select>

                                {formData.additionalOwners.map((owner, index) => (
                                    <div key={index}>
                                        <label>Owner {index + 2} Details:</label>
                                        <StyledInput
                                            type="text"
                                            placeholder="First Name"
                                            value={owner.firstName}
                                            onChange={(e) => handleAdditionalOwnerChange(index, 'firstName', e.target.value)}
                                        />
                                        <StyledInput
                                            type="text"
                                            placeholder="Last Name"
                                            value={owner.lastName}
                                            onChange={(e) => handleAdditionalOwnerChange(index, 'lastName', e.target.value)}
                                        />
                                        <StyledInput
                                            type="email"
                                            placeholder="Email"
                                            value={owner.email}
                                            onChange={(e) => handleAdditionalOwnerChange(index, 'email', e.target.value)}
                                        />
                                        <StyledInput
                                            type="tel"
                                            placeholder="Phone"
                                            value={owner.phone}
                                            onChange={(e) => handleAdditionalOwnerChange(index, 'phone', e.target.value)}
                                        />
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                )}

                <ButtonRoundedText onClick={handleNextStep}>Next</ButtonRoundedText>
                {error && <StyledError>{error}</StyledError>}
            </WrapperSmall>
        </StyledSection>
    );
};

const PropertyCard = styled.div`
  width: 100%;
  transition: all 0.16s ease-out;
  background-color: white;
  padding: .4em .4em .8em .4em;
  box-sizing: border-box;       
  box-shadow: 0px 4px 29px 0px rgba(255, 199, 1, 0.16);
  border-radius: 4px;
  border: 1px solid #dedede;
  margin-bottom: 20px;
  cursor: pointer;
  
  &:hover {
    transform: translateY(-4px);
    outline: 2.4px solid #FFC701;
  }
`;

const PropertyCardContent = styled.div`
  padding: .8em;
  transition: all 1s ease-out;
`;

const PropertyCardTitle = styled.h4`
  font-weight: 600;
  font-size: 1.24em;
`;

const PropertyCardDescription = styled.p`
  margin-top: 0.5em;
  height: auto;
  transition: all 1s ease-out;
`;

const PropertyButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: auto;
  padding: 0;
  margin: 0;
`;

const PropertySelectButton = styled.div`
  margin-top: 1em;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 2.4px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    display: ${props => props.selected ? 'block' : 'none'};
    width: 32px;
    height: 32px;
    background-image: url(${tick});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: -15px;
    left: -4px;
  }
`;

const AgencyInput = styled(StyledInput)`
  margin-top: 20px;
`;

const StepSeven = ({ formData, setFormData, onNextStep, setError, error, setProgress }) => {
    useEffect(() => {
        setProgress("70%");
        window.scrollTo(0, 0);
    }, []);

    const handleSelection = (hasManager) => {
        setFormData({
            ...formData,
            hasPropertyManager: hasManager,
            propertyManagerDetails: hasManager === 'yes' ? formData.propertyManagerDetails : {}
        });
        setError('');
    };

    const handlePropertyManagerChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            propertyManagerDetails: { ...formData.propertyManagerDetails, [name]: value }
        });
    };

    const handleLocalNextStep = () => {
        if (formData.hasPropertyManager === '') {
            setError("Please select whether you have a property manager or not.");
            return;
        }

        if (formData.hasPropertyManager === 'yes') {
            const { agency, agentsName, phone, email } = formData.propertyManagerDetails;
            if (!agency || !agentsName || !phone || !email) {
                // setError("Please fill in all property manager details.");
                toastError("Please fill in all property manager details.");

                return;
            }
            // Basic email validation
            if (!/\S+@\S+\.\S+/.test(email)) {
                // setError("Please enter a valid email address for the property manager.");
                toastError("Please enter a valid email address for the property manager.");
                return;
            }
        }

        // setError('');
        onNextStep();
    };

    return (
        <StyledSection
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
                type: 'spring',
                stiffness: 180,
                damping: 10,
                mass: 0.75,
                delay: .2,
            }}
        >
            <WrapperSmall>
                <div className="titleContainer">
                    <h2>Property Management</h2>
                    <p>Please tell us about your property management arrangement.</p>
                </div>
                <PropertyCard onClick={() => handleSelection('yes')}>
                    <PropertyCardContent>
                        <PropertyCardTitle>Property Manager</PropertyCardTitle>
                        <PropertyCardDescription>I have a professional property manager</PropertyCardDescription>
                        <PropertyButtonContainer>
                            <PropertySelectButton selected={formData.hasPropertyManager === 'yes'} />
                        </PropertyButtonContainer>
                    </PropertyCardContent>
                </PropertyCard>
                <PropertyCard onClick={() => handleSelection('no')}>
                    <PropertyCardContent>
                        <PropertyCardTitle>Self-Managed</PropertyCardTitle>
                        <PropertyCardDescription>I manage the property myself, but im open to getting a property manager.</PropertyCardDescription>
                        <PropertyButtonContainer>
                            <PropertySelectButton selected={formData.hasPropertyManager === 'no'} />
                        </PropertyButtonContainer>
                    </PropertyCardContent>
                </PropertyCard>

                {formData.hasPropertyManager === 'yes' && (
                    <>
                        <label>Your Property Mangers Details:</label>
                        <AgencyInput
                            id="agencyName"
                            name="agency"
                            type="text"
                            placeholder="Property Management Agency Name"
                            value={formData.propertyManagerDetails.agency || ''}
                            onChange={handlePropertyManagerChange}
                            required
                            aria-label="Property Management Agency Name"
                        />
                        <AgencyInput
                            id="agentsName"
                            name="agentsName"
                            type="text"
                            placeholder="Agent's Name"
                            value={formData.propertyManagerDetails.agentsName || ''}
                            onChange={handlePropertyManagerChange}
                            required
                            aria-label="Agent's Name"
                        />
                        <AgencyInput
                            id="agentPhone"
                            name="phone"
                            type="tel"
                            placeholder="Agent's Phone Number"
                            value={formData.propertyManagerDetails.phone || ''}
                            onChange={handlePropertyManagerChange}
                            required
                            aria-label="Agent's Phone Number"
                        />
                        <AgencyInput
                            id="agentEmail"
                            name="email"
                            type="email"
                            placeholder="Agent's Email"
                            value={formData.propertyManagerDetails.email || ''}
                            onChange={handlePropertyManagerChange}
                            required
                            aria-label="Agent's Email"
                        />
                    </>
                )}

                <ButtonRoundedText onClick={handleLocalNextStep}>Next</ButtonRoundedText>
                {error && <StyledError>{error}</StyledError>}
            </WrapperSmall>
        </StyledSection>
    );
};

const StepEight = ({ formData, setFormData, onNextStep, setError, error, setProgress }) => {
    useEffect(() => {
        setProgress("80%");
        window.scrollTo(0, 0);
    }, []);

    const formatNumber = (num) => {
        const cleanNum = num.replace(/[^\d.]/g, '');
        const parts = cleanNum.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join('.');
    };

    const handleInputChange = (index, field, value) => {
        let formattedValue = value;
        if (field === 'mortgageBalance') {
            formattedValue = formatNumber(value);
        }

        setFormData(prev => ({
            ...prev,
            investmentProperties: prev.investmentProperties.map((prop, i) => 
                i === index ? {...prop, [field]: formattedValue} : prop
            )
        }));
        setError('');
    };

    const handleLocalNextStep = () => {
        const isValid = formData.investmentProperties.every(prop => {
            const balance = parseFloat(prop.mortgageBalance?.replace(/,/g, '') || '');
            if (isNaN(balance)) {
                return false; // Invalid if balance is not a number
            }
            if (balance > 0) {
                return prop.mortgageType;
            }
            return true;
        });

        if (!isValid) {
            // setError('Please enter a mortgage balance (or 0 if there is no mortgage) and select a structure.');
            toastError('Please enter a mortgage balance (or 0 if there is no mortgage) and select a mortgage type.');
            return;
        }
        // setError('');
        onNextStep();
    };

    return(
        <StyledSection
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
                type: 'spring',
                stiffness: 180,
                damping: 10,
                mass: 0.75,
                delay: .2,
            }}
        >
        <WrapperSmall>
            <div className="titleContainer">
                <h2>Mortgage Details</h2>
                <p>Please enter your property's outstanding mortgage balance and mortgage structure.</p>
                <br></br>
                <p>If you don't have a mortgage, please set your balance to 0.</p>
            </div>
            {formData.investmentProperties.map((property, index) => (
                <div key={index}>
                    <label style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                       {property.address}:
                    </label>
                    <StyledInput
                        id={`mortgage-${index}`}
                        name={`mortgage-${index}`}
                        type="text"
                        placeholder="Outstanding mortgage balance"
                        value={property.mortgageBalance || ''}
                        onChange={e => handleInputChange(index, 'mortgageBalance', e.target.value)}
                        required
                        aria-label={`Mortgage balance for ${property.address}`}
                    />
                    {(parseFloat(property.mortgageBalance?.replace(/,/g, '') || '0') > 0 || property.mortgageBalance === '') && (
                        <>
                            {/* Interest rate input removed */}
                            <select
                                id={`mortgage-type-${index}`}
                                name={`mortgage-type-${index}`}
                                value={property.mortgageType || ''}
                                onChange={e => handleInputChange(index, 'mortgageType', e.target.value)}
                                required
                                aria-label={`Mortgage type for ${property.address}`}
                            >
                                <option value="">Select mortgage type</option>
                                <option value="interest-only">Interest Only</option>
                                <option value="principal-and-interest">Principal & Interest</option>
                                <option value="split">Split</option>
                            </select>
                        </>
                    )}
                </div>
            ))}
            <ButtonRoundedText onClick={handleLocalNextStep}>Next</ButtonRoundedText>
            {/* {error && <StyledError>{error}</StyledError>} */}
        </WrapperSmall>
        </StyledSection>
    );
};

const StepNine = ({ formData, setFormData, onNextStep, setError, error, setProgress }) => {
    useEffect(() => {
        setProgress("85%");
        window.scrollTo(0, 0);
    }, []);

    const handleLocalNextStep = () => {
        const { licenceNum, licenceVer } = formData.KYC;
        const { residentialAddress, dob } = formData.personalDetails;
        if (!licenceNum || !licenceVer || !residentialAddress || !dob) {
            // setError('Please fill in all fields.');
            toastError('Please fill in all fields.');
            return;
        }
        // Add more specific validations if needed (e.g., date format for DOB)
        // setError(''); // Clear error if validation passes
        onNextStep();
    };

    const kycAndPersonalFields = [
        { section: 'KYC', type: 'text', placeholder: 'Drivers license number', key: 'licenceNum', id: 'licenceNum', name: 'licenceNum' },
        { section: 'KYC', type: 'text', placeholder: 'Drivers version number', key: 'licenceVer', id: 'licenceVer', name: 'licenceVer' },
        { section: 'personalDetails', type: 'text', placeholder: 'Your date of birth', key: 'dob', id: 'dob', name: 'dob' },
        { section: 'personalDetails', type: 'text', placeholder: 'Current residential address', key: 'residentialAddress', id: 'residentialAddress', name: 'residentialAddress' }
    ];

    return(
        <>
        <StyledSection
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
                type: 'spring',
                stiffness: 180,
                damping: 10,
                mass: 0.75,
                delay: .2,
            }}
        >
        <WrapperSmall>
            <div className="titleContainer">
                <h2>Identity Verification</h2>
                <p>Add your details below so we can verify your identity.</p>
            </div>
            {kycAndPersonalFields.map((field) => (
    field.key === 'residentialAddress' ? (
        <StyledAutocomplete
            key={field.key}
            apiKey={String(process.env.REACT_APP_GOOGLE_API_KEY)}
            onPlaceSelected={(place) => {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    [field.section]: {
                        ...prevFormData[field.section],
                        [field.key]: place.formatted_address
                    }
                }));
            }}
            options={{
                types: ['address'],
                componentRestrictions: { country: "nz" }
            }}
            placeholder={field.placeholder}
            value={formData[field.section][field.key]}
            onChange={(e) => setFormData(prevFormData => ({
                ...prevFormData,
                [field.section]: {
                    ...prevFormData[field.section],
                    [field.key]: e.target.value
                }
            }))}
        />
    ) : (
        <StyledInput
            key={field.key}
            id={field.id}
            name={field.name}
            type={field.type}
            placeholder={field.placeholder}
            value={formData[field.section][field.key]}
            onChange={(e) => setFormData(prevFormData => ({
                ...prevFormData,
                [field.section]: { ...prevFormData[field.section], [field.key]: e.target.value }
            }))}
            required
            aria-label={field.placeholder}
        />
    )
))}

            <ButtonRoundedText onClick={handleLocalNextStep}>Next</ButtonRoundedText>
            {/* {error && <StyledError>{error}</StyledError>} */}
        </WrapperSmall>
        </StyledSection>
        </>
    );
};

const SelectableDiv = styled.div`
  cursor: pointer;
  padding: 10px;
  margin: 1em 0;
  border-radius: 8px;
  border: 1px solid #dedede;
`;

const TickBox = styled.span`
position: absolute; // Needed for absolute positioning inside
display: inline-flex;
align-items: center;
justify-content: center;
width: 20px;
height: 20px;
margin-right: 10px;
border: 2.4px solid #dedede;
border-radius: 100px;
flex-shrink: 0; // Prevent the tick box from shrinking
&::after {
  content: '';
  display: ${props => props.$isSelected ? 'block' : 'none'};
  width: 32px;
  height: 32px;
  background-image: url(${tick}); // Replace with your image path
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: -15px;
  left: -4px;
}
`;

const OptionText = styled.div`
    margin-left: 2em;
    font-size: 16px;
    letter-spacing: -.5px;
    line-height: 140%;
    opacity: .8;
    font-weight: 500;
`;

const SelectableCheckbox = ({ label, onToggle, isSelected }) => {
    return (
        <SelectableDiv onClick={() => onToggle(label)}>
        <TickBox $isSelected={isSelected} />
        <OptionText>{label}</OptionText>
      </SelectableDiv>
    );
  };

  const StepTen = ({ formData, setFormData, onNextStep, setError, error, setProgress, setDocId, docId }) => {
    useEffect(() => {
        setProgress("95%");
        window.scrollTo(0, 0);
    }, []); 
    const [isRequesting, setIsRequesting] = useState(false);

    const toggleConsent = (consentType) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            consents: {
                ...prevFormData.consents,
                [consentType]: !prevFormData.consents[consentType]
            }
        }));
        // setError(''); // Clear error when user interacts with consent checkboxes
    };

    const handleLocalNextStep = async () => {
        const { consents } = formData;

        if (!consents.privacyPolicyConsent || !consents.creditCheckAndIdConsent) {
            // setError('Please agree to our privacy policy and consent to a credit check.');
            toastError('Please agree to our privacy policy and consent to a credit check.');

            return;
        }

        setError(''); // Clear error if validation passes
        setIsRequesting(true);
        try {
            const docRef = await addApplication(formData);
            setDocId(docRef);
            // await handleCreateDeal(formData);
            sendSlackNotification(formData);
            handleSendEmail(formData);
            handleSendUserEmail(docRef, formData);
            onNextStep();
        } catch (error) {
            toastError('An error occurred while submitting your application. Please try again.');
        } finally {
            setIsRequesting(false);
        }
    };

    return (
        <>
            <StyledSection
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={{
                    type: 'spring',
                    stiffness: 180,
                    damping: 10,
                    mass: 0.75,
                    delay: .2,
                }}
            >
                <WrapperSmall>
                    <div className="titleContainer">
                        <h2>Privacy Policy & Consents</h2>
                        <p>We need your consent to run a soft credit check, which will not affect your credit score, and to verify your identity. You can view our <Link to="/privacy" target="_blank">privacy policy</Link> for more details.</p>
                        </div>
                    <div>
                        <SelectableCheckbox
                            id="privacyPolicy"
                            label="I agree to LeFi's privacy policy"
                            isSelected={formData.consents.privacyPolicyConsent}
                            onToggle={() => toggleConsent('privacyPolicyConsent')}
                        />
                        <SelectableCheckbox
                            id="marketingConsent"
                            label="I consent to receiving marketing emails from LeFi"
                            isSelected={formData.consents.marketingConsent}
                            onToggle={() => toggleConsent('marketingConsent')}
                        />
                        <SelectableCheckbox
                            id="creditCheck"
                            label="I consent to LeFi conducting a soft credit check on me, and collecting other information about me from third parties like identity verification providers, property searches, to assess my eligibility for an advance and to verify the information I provide to LeFi."
                            isSelected={formData.consents.creditCheckAndIdConsent}
                            onToggle={() => toggleConsent('creditCheckAndIdConsent')}
                        />
                    </div>

                    <ButtonRoundedText onClick={handleLocalNextStep} disabled={isRequesting}>
                        {isRequesting ? "Submitting.." : "Submit your Application"}
                    </ButtonRoundedText>
                    {/* {error && <StyledError>{error}</StyledError>} */}
                </WrapperSmall>
            </StyledSection>
        </>
    );
};


const StyledSummary = styled.div`
    margin-top: 2.4em;
    margin-bottom: 300px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    h2 {margin-bottom: 2em;}
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
    .full-width {
        grid-column: 1 / -1;
    }

    .two-thirds {
        grid-column: span 2;
        @media (max-width: 768px) {
            grid-column: 1 / -1;
        }
    }

    .one-third {
        grid-column: span 1;
        @media (max-width: 768px) {
            grid-column: 1 / -1;
        }
    }

    .summary-item {
        // background-color: #f9f9f9;
        border: .4px solid #dedede;
        border-radius: 8px;
        padding: .3em 1em 1em 1em;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #f9f9f9;
        label {
            display: block;
            font-weight: 500;
            margin-bottom: 0em;
            color: #666;
            font-size: 14px;
            letter-spacing: -.4px;
        }
        
        p {
            margin: 0;
            font-size: 1em;
            color: #000;
        }
    }
`;

const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const CashflowStep = ({ formData, setFormData, onNextStep, setError, error, setProgress }) => {
    useEffect(() => {
        setProgress("82%");
        window.scrollTo(0, 0);
    }, []);

    const handleCashflowSelection = (isPositive) => {
        setFormData({ ...formData, cashflowPositive: isPositive });
        // setError('');
    };

    const handleLocalNextStep = () => {
        if (formData.cashflowPositive === null || formData.cashflowPositive === undefined) {
            // setError('Please select whether your cashflow is positive or negative.');
            toastError('Please select whether your cashflow is positive or negative.');
            return;
        }
        // setError('');
        onNextStep();
    };

    return (
        <StyledSection
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{
                type: 'spring',
                stiffness: 180,
                damping: 10,
                mass: 0.75,
                delay: .2,
            }}
        >
            <WrapperSmall>
                <div className="titleContainer">
                    <h2>What best describes your cashflow?</h2>
                    <p>(Rent + Wages - All Expenses)</p>
                </div>
                
                <PropertyCard onClick={() => handleCashflowSelection(true)}>
                    <PropertyCardContent>
                        <PropertyCardTitle>Positive Cashflow</PropertyCardTitle>
                        <PropertyCardDescription>My income exceeds my expenses.</PropertyCardDescription>
                        <PropertyButtonContainer>
                            <PropertySelectButton selected={formData.cashflowPositive === true} />
                        </PropertyButtonContainer>
                    </PropertyCardContent>
                </PropertyCard>

                <PropertyCard onClick={() => handleCashflowSelection(false)}>
                    <PropertyCardContent>
                        <PropertyCardTitle>Negative Cashflow</PropertyCardTitle>
                        <PropertyCardDescription>My expenses exceed my income.</PropertyCardDescription>
                        <PropertyButtonContainer>
                            <PropertySelectButton selected={formData.cashflowPositive === false} />
                        </PropertyButtonContainer>
                    </PropertyCardContent>
                </PropertyCard>
                
                <ButtonRoundedText onClick={handleLocalNextStep}>Next</ButtonRoundedText>
                {/* {error && <StyledError>{error}</StyledError>} */}
            </WrapperSmall>
        </StyledSection>
    );
};
  

const OwnerSection = styled.div`
  margin-bottom: 30px;
  border: 1px solid #dedede;
  padding: 20px;
  border-radius: 8px;
//   background-color: #f3f3f3;

`;

const PageWrapper = styled.div`
  padding-bottom: 200px; 
`;

const StepEleven = ({ formData, setFormData, onNextStep, setError, error, setProgress, docId }) => {
    useEffect(() => {
        setProgress("100%");
        window.scrollTo(0, 0);
    }, []);

    const calculateTotalRequestedAmount = () => {
        if (formData.weeklyRent) {
            const amount = Math.round((formData.weeklyRent * 52 * (formData.monthsAdvance / 12)));
            return Math.min(amount, 100000); // Cap at 100,000
        }
        return 0;
    };

    const totalRequestedAmount = calculateTotalRequestedAmount();
    const isMaxedOut = totalRequestedAmount === 100000;


    return (
        <StyledSection>
            <Wrapper>
                <h2>Application Summary</h2>
                <p>Congratulations on submitting your application to unlock your future rental income. One of our rental income advance experts will review your application and get in touch with you shortly.</p>
                <StyledSummary>
                    <div className="summary-item two-thirds">
                        <label>Application ID:</label>
                        <p>{docId || 'Not available'}</p>
                    </div>

                    <div className="summary-item one-third">
                        <label>Status:</label>
                        <p>In Progress</p>
                    </div>

                    <div className="summary-item one-third">
                        <label>Weekly Rent:</label>
                        <p>${formData.weeklyRent ? formData.weeklyRent.toLocaleString('en-US') : 'Not specified'}</p>
                    </div>

                    <div className="summary-item two-thirds">
                        <label>Total Requested Amount:</label>
                        <p>
                            ${totalRequestedAmount.toLocaleString('en-US')} ({formData.monthsAdvance} months)
                        </p>
                    </div>

                    <div className="summary-item two-thirds">
                        <label>Use of Funds:</label>
                        <p>{formData.useOfFunds || 'Not specified'}</p>
                    </div>

                    <div className="summary-item one-third">
                        <label>Timeline:</label>
                        <p>{formData.timeline || 'Not specified'}</p>
                    </div>

                    <div className="summary-item one-third">
                        <label>Cashflow:</label>
                        <p>{formData.cashflowPositive ? 'Positive' : 'Negative'}</p>
                    </div>

                    <div className="summary-item two-thirds">
                        <label>Investment Properties:</label>
                        {formData.investmentProperties.map((property, index) => (
                            <p key={index}>{property.address} - {property.type}</p>
                        ))}
                    </div>

                    <div className="summary-item two-thirds">
                        <label>Ownership Structure:</label>
                        <p>
                            {formData.isCompanyOrTrustOwnership ? 'Company or Trust' : 
                             formData.isJointOwnership ? 'Joint Personal Ownership' : ' Personal Ownership'}
                        </p>
                    </div>

                    <div className="summary-item one-third">
                        <label>Number of Owners:</label>
                        <p>{formData.ownerCount}</p>
                    </div>

                    {/* {formData.isJointOwnership && formData.additionalOwners.map((owner, index) => (
                        <React.Fragment key={index}>
                            <div className={`summary-item ${index % 2 === 0 ? 'two-thirds' : 'one-third'}`}>
                                <label>Additional Owner {index + 1}:</label>
                                <p>{owner.firstName} {owner.lastName}</p>
                                <p>Email: {owner.email}</p>
                                <p>Phone: {owner.phone}</p>
                            </div>
                        </React.Fragment>
                    ))} */}

                    <div className="summary-item one-third">
                        <label>Property Management:</label>
                        <p>{formData.hasPropertyManager === 'yes' ? 'Professional Manager' : 'Self-Managed'}</p>
                    </div>

                    {formData.hasPropertyManager === 'yes' && (
                        <div className="summary-item two-thirds">
                            <label>Property Manager Details:</label>
                            <p>Agency: {formData.propertyManagerDetails.agency}</p>
                            <p>Agent: {formData.propertyManagerDetails.agentsName}</p>
                            <p>Contact: {formData.propertyManagerDetails.phone}, {formData.propertyManagerDetails.email}</p>
                        </div>
                    )}

                    {formData.isJointOwnership && formData.additionalOwners.map((owner, index) => (
                        <div key={index} className="summary-item full-width">
                            <label>Additional Owner {index + 1}:</label>
                            <p>{owner.firstName} {owner.lastName}</p>
                            <p>Email: {owner.email}</p>
                            <p>Phone: {owner.phone}</p>
                        </div>
                    ))}
                </StyledSummary>
            </Wrapper>
        </StyledSection>
    );
};

const ApplicationNav = ({step, setStep, progress, }) => {

    const [isMenuOpen, setMenuOpen] = useState(false);

    const navigate = useNavigate();


    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
      };

    const handleBackClick = () => {
        if (step === 1) {
            navigate('/');
        } else {
            setStep(prevStep => prevStep - 1);
        }
      };

    return (
        <>
        <StyledNav>
            <ProgressBar progress={progress} />

            <div className="left">
                <ButtonGray onClick={handleBackClick}><img className="back" src={arrow} alt="arrow" />Back</ButtonGray>
            </div>
            <div className="center">
                <Link to="/"><img src={logo} alt="logo"/></Link>
            </div>
            <div className="right">
            <ButtonGray onClick={toggleMenu}>
                <img src={burger} alt="burger" />
                  {isMenuOpen ? 'Close' : 'Steps'}
            </ButtonGray>
            </div>

        </StyledNav>
        <StepsMenu isopen={isMenuOpen ? 'true' : undefined}>
        <h3>Steps</h3>
        <ul>
            <li>
                <h4>Estimate your Advance</h4>
                <p>Estimate your rental income advance amount and tell us about your master plan.</p>
            </li>
            <li>
                <h4>Property Details</h4>
                <p>We need to check your property’s eligibility by learning a little about if you have a mortgage and your ownership structure.</p>
            </li>
            <li>
                <h4>Identification</h4>
                <p>We need some basic drivers license information to verify you are who you say you are.</p>
            </li>
            <li>
                <h4>Submit your Application</h4>
                <p>Once your application is sent off one of our experts will work to verify your property's cashflow with you and let you know how much you could unlock!</p>
            </li>
        </ul>
    </StepsMenu>
    </>
        
    );
}

const Application = () => {
    const [docId, setDocId] = useState("");
    const [step, setStep] = useState(1);
    const [error, setError] = useState("");
    const [progress, setProgress] = useState("5%")
    const [formData, setFormData] = useState({
        // Ownership structure
        isPersonalOwnership: false, // Boolean for personal ownership
        isCompanyOrTrustOwnership: false, // Boolean for company or trust ownership
        isJointOwnership: false, // Boolean for joint ownership
    
        // Ownership count
        ownerCount: 1, // Tracks the number of total owners (1 for single, >1 for joint ownership)
    
        // Personal details for main owner
        personalDetails: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            residentialAddress: '',
            dob: ''
        },
    
        // Properties
        investmentProperties: [], // Array to store investment property details
    
        // Property manager details
        propertyManagerDetails: {
            agency: '',
            agentsName: '',
            phone: '',
            email: ''
        },
        hasPropertyManager: false, // Boolean to track if a property manager exists
    
        // KYC details for the main owner
        KYC: {
            licenceNum: '',
            licenceVer: ''
        },
    
        // Consent options
        consents: {
            privacyPolicyConsent: false, // Boolean for privacy policy consent
            marketingConsent: false, // Boolean for marketing consent
            creditCheckAndIdConsent: false // Boolean for credit check and ID consent
        },
    
        // Joint ownership additional owner details
        additionalOwners: [], // Array to store details of additional owners in case of joint ownership
    
        // Financial information
        weeklyRent: '', // Weekly rent amount
        monthsAdvance: null, // Number of months for rent advance
        cashflowPositive: null, // Cashflowing
    
        // Advance details
        useOfFunds: '', // The intended use of the funds
        timeline: '', // Timeline for use of the funds
    });

    const location = useLocation();
    const navigate = useNavigate();

    const updateStepAndURL = useCallback((newStep) => {
        const params = new URLSearchParams(location.search);
        if (formData.weeklyRent) {
            params.set('weeklyRent', formData.weeklyRent.toString());
        }
        navigate(`/apply?${params.toString()}`, { replace: true });
        setStep(newStep);
    }, [navigate, location.search, formData.weeklyRent]);

    // Updated useEffect hook
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const weeklyRentFromURL = params.get('weeklyRent');

        if (weeklyRentFromURL) {
            setFormData(prevState => ({
                ...prevState,
                weeklyRent: weeklyRentFromURL,
            }));
        }
    }, [location.search]);

    const handleNextStep = useCallback(() => {
        setStep(step + 1);
    }, [step]);

    const handlePreviousStep = useCallback(() => {
        if (step > 1) {
            setStep(prevStep => prevStep - 1);
        } else {
            navigate('/');
        }
    }, [step, navigate]);

    const stepComponents = [
        StepOne,
        StepTwo,
        StepThree,
        StepFour,
        StepFive,
        StepSix,
        StepSeven,
        StepEight,
        CashflowStep,
        StepNine,
        StepTen,
        StepEleven
        // Add more as needed
    ];

    const commonProps = {
        formData: formData,
        setFormData: setFormData,
        onNextStep: handleNextStep,
        setError: setError,
        error: error,
        setProgress: setProgress,
        setDocId,
        docId //docId states not very dry..
    };

    const renderStep = () => {
        const StepComponent = stepComponents[step - 1];
        return StepComponent ? <StepComponent {...commonProps} /> : <div>Unknown step</div>;
    };

    return (
        <>
        <ApplicationNav step={step} setStep={setStep} progress={progress} />
        <Toaster
        position="top-right"
        reverseOrder={false}
        containerStyle={{
            top: 64,
            left: 16,
            bottom: 16,
            right: 16,
          }}
        />
        {renderStep()}
        <StyledFooter>
                <div>
                    <img src={tick} alt="tick" />
                    Zero Impact on your Credit File
                </div>
                <div>
                    <img src={tick} alt="tick" />
                    Zero Commitment Application
                </div>
        </StyledFooter>
        </>
    )
}

export default Application;