import { createGlobalStyle, styled } from "styled-components";

const themes = {
    light: {
      background: "#FFFFFF",
      foreground: "#000000",
      primary: "#FFB600",
      secondary: "#6C757D",
      border: "#E0E0E0",
      text: {
        primary: "#393939",
        secondary: "gray",
        link: "#FFD700"
      },
      button: {
        background: "#FFD700",
        text: "#252525"
      }
    },
    dark: {
      background: "#121212",
      foreground: "#E0E0E0",
      primary: "#FFB600",
      secondary: "#2A2A2A",
      border: "#333333",
      text: {
        primary: "#E0E0E0",
        secondary: "#B0B0B0",
        link: "#FFD700"
      },
      button: {
        background: "#FFD700",
        text: "#121212"
      }
    }
  }


  const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    position: relative;
    background-color: ${props => props.theme === 'light' ? themes.light.background : themes.dark.background};
    color: ${props => props.theme === 'light' ? themes.light.text.primary : themes.dark.text.primary};
    font-family: 'Montserrat', sans-serif; // Use any font-family you prefer
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; // Smooth transition for theme toggle
    font-size: 16px;
    overflow-x: hidden;
    width: 100vw;
  }

  a {
    color: #FFC701;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }

  small {
  }

  a:hover {
    text-decoration: underline;
  }

  h1 {
    font-size: 4em;
    letter-spacing: -4.8px;
    font-weight: 600 !important;
    @media (max-width: 720px) {
      font-size: 2.4em; // add meedia queries
      letter-spacing: -3.2px;
      text-align: left;
    }
  }
  h2 {
    font-size: 2.4em;
    letter-spacing: -2.4px;
    font-weight: 600 !important;
    @media (max-width: 720px) {
      font-size: 1.8em;
      letter-spacing: -1.8px;
    }
  }
  h3 {
    font-size: 1.6em;
  }
  p {
    line-height: 1.6;
    font-weight: 500;
    font-size: 16px;
    color: ${props => props.theme === 'light' ? themes.light.text.secondary : themes.dark.text.primary};
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme === 'light' ? themes.light.text.primary : themes.dark.text.primary};
    margin-top: 0;
    font-weight: 400;
  }
  section {
    padding: 0em 1.6em;
  }
  footer {
    padding: 2em 1.6em;
  }
  .container {
    max-width: 720px;
    margin: 0 auto;
    padding: 0 15px;
    background: ${props => props.theme === 'light' ? themes.light.foreground : themes.dark.foreground};
    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  input, text-area, button, label, select {
    width: 100%;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    border: none;
    padding: 1em;
  }

  button {
    margin-bottom: 1em;
    padding: .8em;

  }

  select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 100px;
  border: 1px solid #dedede;
  font-weight: 500;
  font-size: 16px;
  background-color: white;
  margin: 0em auto 1em auto;
  transition: border-color 0.3s ease;
  width: 100%;
  text-align: left;
  background: white url("data:image/svg+xml;utf8,<svg fill='black' height='24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat right 1rem center;
   padding-right: 1.5rem; /* Add padding to make space for the custom arrow */
   color: black;
  }


  input, select {
    box-sizing: border-box;
    border-radius: 100px;
    outline-radius: 100px;
    -webkit-outline-radius: 100px !important;
    &:focus {
      box-sizing: border-box;
      outline-color: #FFC701;
      outline-style: solid;
      outline-width: 2.4px;
      outline-offset: 1.6px;
      outline-radius: 100px;
      border: 1px solid #dedede;
      }
      @media screen and (-webkit-min-device-pixel-ratio:0) {
        &:focus {
          outline: none;
        }
      }
 }//issues with this code on firefox 
  

  button:hover {
    opacity: 0.8;
  }

  iframe {
    height: 32px;
    width: 32px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
  }

  header {
    padding: 5.6rem 0 3rem 0;
    position: relative;
  }
  .btn-solid {
    // background-color: rgb(255, 249, 231);
    background-color: #FFC701;
    padding: .72em 1em;
    border-radius: 100px;
    @media (max-width: 756px) {
      font-size: 14px !important;
    }
    &:hover {
      text-decoration: none;
      // outline: 2.4px solid rgba(255, 199, 1, 1);
      opacity: .8;
    }
  }
`;

export const CTA = styled.div`
  text-align: left;
  padding: 1.6em;
  margin: 1em auto 1.6rem auto;
  @media(min-width: 720px) {
    text-align: center;
  }
  h1, p {
    max-width: 720px;
    margin: .5em auto;
  }
  p {
    font-size: 18px;
    @media(min-width: 720px) {
      font-size: 21px;
      max-width: 660px;
    }
  }
`;

export const WrapperSmall = styled.div`
  max-width: 480px;
  margin: auto;
  // padding: 1.6em;
`;

export const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
  padding: .4em;
`;

export const WrapperLarge = styled.div`
  max-width: 1100px;
  margin: auto;
`;


export const ButtonUnderline = styled.button`
  text-decoration: underline;
  font-weight: 600;
  color: ${props => props.theme === 'light' ? themes.light.primary : themes.dark.primary};
  cursor: pointer;
  border: none;
  background: none;
  text-align: left;
`;

export const CircleButton = styled.button`
  position: absolute;
  border-radius: 100px;
  box-shadow: 0px 4px 29px 0px rgba(255, 199, 1, 0.47);
  width: 40px;
  height: 40px;
  top: 4px;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFC701;
`;

export const ButtonRoundedText = styled.button`
  display: block;
  text-align: center;
  border-radius: 100px;
  background-color: #FFC701;
  font-weight: 600;
  margin-bottom: 1em;
  color: black !important;
`;

export const ButtonRoundedLight = styled.button`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #f3f3f3;
  font-weight: 600;
  margin-bottom: 1em;
  color: black !important;
`;

export const ButtonRoundedTextGray = styled.button`
  display: block;
  text-align: center;
  border-radius: 100px;
  background-color: white;
  font-weight: 600;
  color: Gray;
`;
export const ButtonRoundedBorder= styled.button`
  display: block;
  text-align: center;
  border-radius: 100px;
  border: 2.4px solid #dedede;
  background-color: white;
  font-weight: 500;
  color: #000;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  left: 50%;
  top: 80px;
  transform: translate(-96%, 0);
  z-index: -20 !important;
  min-width: 1500px;
`;

export const BackgroundImage2 = styled.img`
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translate(-52.75%, 0);
  z-index: -20 !important;
  max-width: 92vw;
  // filter: blur(4px);
`;


export const BackgroundImage3 = styled.img`
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translate(-52.75%, 0);
  z-index: -20 !important;
  max-width: 92vw;
  // filter: blur(32px);
`;


export const StyledInput = styled.input`
  border-radius: 100px;
  border: 1px solid #dedede;
  font-size: 16px;
  font-weight: 500;
  background: #fff;
  height: 48px;
  margin-bottom: 1em;
  outline-offset: 1.6px;
  position: relative;
  display: block;
  text-align: left;
`;

export const StyledInputWeekly = styled.input`
  border-radius: 100px;
  border: 1px solid #dedede;
  font-size: 16px;
  font-weight: 500;
  background: #fff;
  height: 48px;
  margin-bottom: 1em;
  padding-left: 2em;
  outline-offset: 1.6px;
  position: relative;
  display: block;
  text-align: left;
`;


export const StyledTextArea = styled.textarea`
  border-radius: 16px;
  padding: 1em;
  text-align: center;
  box-shadow: 0px 4px 29px 0px rgba(255, 199, 1, 0.16);
  font-size: 16px;
  font-weight: 500;
  background: #fff;
  height: 48px;
  margin-bottom: 1em;
  outline: 1px solid #dedede;
  border: none;
  height: 100px;
  width: 100%;
`;


export const RentForm = styled.form`
max-width: 400px;
margin: 3.2rem auto;
font-size: 16px;
label {
  font-weight: 600;
  font-size: 16px;
}
input {
    border-radius: 100px !important;
    -webkit-border-radius: 100px !important;
    -moz-border-radius: 100px;
    box-shadow: 0px 4px 29px 0px rgba(255, 199, 1, 0.56);
    font-size: 19px;
    font-weight: 500;
    background: #fff;
    height: 48px;
    border: 3.2px solid #FFC701;
}

.inputWrapper {
    display: block;
    position: relative;
    margin: 0 2em;
    border-radius: 100px !important;
    -webkit-border-radius: 100px !important;
    -moz-border-radius: 100px;
    &::before {
        content: "$";
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%); /* Center vertically */
        pointer-events: none; /* Make sure the pseudo-element doesn't interfere with input focus */
        font-weight: 600;
        font-size: 1.2em;
      }
}
button {
  position: absolute;
  border-radius: 100px;
  box-shadow: 0px 4px 29px 0px rgba(255, 199, 1, 0.47);
  width: 38px;
  height: 38px;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFC701;
}
p {
  font-size: 16px;
  color: #FFC701;
  margin-top: 16px;
}
`;


export const LeFiPoints = styled.section``;

export const GridContainer3 = styled.div`
  margin-top: 2.4em;
  margin-bottom: 4em;
  @media (min-width: 720px) { 
    grid-template-columns: repeat(3, 1fr);
    gap: 16px; /* Adjust as needed */
    display: grid;
  }
  div {
    margin-bottom: 3.2em;

    img {
      max-height: 24px;
      margin-bottom: 1em;
    }
    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: .4em;
    }
    p {
      font-size: 1em;
    }
  }
`
  
export default GlobalStyles;
